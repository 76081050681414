import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { withSnackbar } from 'notistack'
import TwitterLogin from 'react-twitter-auth'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { UserContext } from 'providers'
import { Modal } from 'components'
import { getCurrentUser, handleHTTPResponse } from 'utils'
import {
  Button,
  FormControl,
  InputLabel,
  Input,
  Grid,
  Typography,
} from '@material-ui/core'
import S from './style'


const TabContainer = (props) => (
  <div style={{marginTop: '10px'}}>
    {props.children}
  </div>
)


class Settings extends React.Component {
  state = {
    email: this.props.user.email,
    name: this.props.user.name,
    pushbullet_api_token: this.props.user.pushbullet_api_token,
  }

  save = async () => {
    this.setState({ loading: true })

    const { email, name, pushbullet_api_token } = this.state
    const data = { email, name, pushbullet_api_token }

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + 'user/settings',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
        body: JSON.stringify(data)
      }
    );
    
      await handleHTTPResponse(response)
      this.props.user.setUser({...this.props.user, email, name })
      this.props.enqueueSnackbar('Settings updated successfully', { variant: 'success' })
      this.props.toggle()
      this.props.closeNavbar && this.props.closeNavbar()
      window.ga && window.ga.getAll()[0] && window.ga.getAll()[0].send({
        hitType: 'event',
        eventCategory: 'Save',
        eventAction: 'settings'
      })
    } catch (e) {
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      this.setState({ loading: false })
    }
  }

  onTwitterSuccess = async (response) => {
    try {
      await handleHTTPResponse(response)
      const user = await getCurrentUser()
      this.user.setUser(user)
    } catch (e) {
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  onTwitterError = () => {
    this.props.enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' })
  }

  onFacebookResponse = async (result) => {
    if (! result.accessToken) {
      this.props.enqueueSnackbar(
        'There was a problem logging you in. Please try again',
        { variant: 'error' }
      )
      return
    }
    const { accessToken } = result
    const data = {
      code: accessToken
    }

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + 'user/facebook/connect',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
        body: JSON.stringify(data)
      })

      await handleHTTPResponse(response)
      const user = await getCurrentUser()
      this.props.user.setUser(user)
    } catch (e) {
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  renderForm = () => (
    <TabContainer>
      <FormControl>
        <InputLabel htmlFor="user-name">Name</InputLabel>
        <Input
          id="user-name"
          inputProps={{name: 'name', id: 'name'}}
          value={this.state.name}
          onChange={(e) => this.setState({ name: e.target.value })}
        />
      </FormControl>
      <br/><br/>
      <FormControl>
        <InputLabel htmlFor="user-email">Email</InputLabel>
        <Input
          id="user-email"
          type="email"
          inputProps={{name: 'email', id: 'email'}}
          value={this.state.email}
          onChange={(e) => this.setState({ email: e.target.value })}
        />
      </FormControl>
      <FormControl>
        <InputLabel htmlFor="pushbullet-api-token">Pushbullet API Token</InputLabel>
        <Input
          id="pushbullet-api-token"
          inputProps={{name: 'pushbullet_api_token', id: 'pushbullet_api_token'}}
          value={this.state.pushbullet_api_token}
          onChange={(e) => this.setState({ pushbullet_api_token: e.target.value })}
        />
      </FormControl>
    </TabContainer>
  )

  renderSocialConnections = () => {
    const twitterConnection = this.props.user.connections.find(con => con.site === 'twitter')
    const twitterName = twitterConnection && twitterConnection.is_connected ? twitterConnection.external_handle : ''

    const facebookConnection = this.props.user.connections.find(con => con.site === 'facebook')
    const facebookName = facebookConnection && facebookConnection.is_connected ? facebookConnection.external_handle : ''

    return (
      <Grid container direction="column" spacing={16} alignItems="center">
        <Grid item />
        <Grid item>
          <TwitterLogin
            onSuccess={this.onTwitterSuccess}
            onFailure={this.onTwitterError}
            customHeaders={{
              'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            }}
            style={{background: 'none', border: 'none'}}
            showIcon={false}
            requestTokenUrl={process.env.REACT_APP_API_URL + 'user/twitter/request_token'}
            loginUrl={process.env.REACT_APP_API_URL + 'user/twitter/connect'}>
              <img src="/img/sign-in-with-twitter.png" alt="sign in with twitter" />
            </TwitterLogin>
        </Grid>
        {twitterName && (
          <Grid item>
            <Typography>Logged in as <strong>@{twitterName}</strong></Typography>
          </Grid>
        )}
        <Grid item>
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            callback={this.onFacebookResponse}
            scope="public_profile"
            render={renderProps => (
              <img onClick={renderProps.onClick} src="/img/sign-in-with-facebook.png"
              alt="sign in with facebook" />
              )}
            />
        </Grid>
        {facebookName && (
          <Grid item>
            <Typography>Logged in as <strong>{facebookName}</strong></Typography>
          </Grid>
        )}
      </Grid>
    )
  }

  render = () => {
    const { open, toggle } = this.props

    return (
      <Modal open={open} toggle={toggle}>
        <Modal.Header toggle={toggle}>Settings</Modal.Header>
        <Modal.Body>
          <S.Container>
            <Grid container spacing={8}>
              <Grid item md={12} lg={6}>{this.renderForm()}</Grid>
              <Grid item md={12} lg={6}>{this.renderSocialConnections()}</Grid>
            </Grid>
          </S.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button color="secondary" onClick={toggle} disabled={this.state.loading}>
            Discard
          </Button>
          <Button color="primary" variant="contained" disabled={this.state.loading}
          onClick={this.save}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

Settings.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
}

export default withSnackbar((props) => {
  const user = useContext(UserContext)
  return <Settings user={user} {...props}  />
})
