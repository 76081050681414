import styled from 'styled-components'
import { IconButton } from '@material-ui/core'

export default {
  SiteIcon: styled.i`
    font-size: 30px;
    ${props => props.site === 'reddit'   && 'color: #fb8c00;'}
    ${props => props.site === 'facebook' && 'color: #1976d2;'}
    ${props => props.site === 'twitter'  && 'color: #90caf9;'}
  `,
  Link: styled.a`
    color: inherit;
    text-decoration: initial;
  `,
  Center: styled.div`
    text-align: center;
  `,
  Description: styled.div`
    & .emoji {
      height: 1em;
      width: 1em;
      margin: 0 .05em 0 .1em;
      vertical-align: -0.1em;
    }
    width: 100%;
  `,
  PostButton: styled(IconButton)`
    margin-left: auto !important;
  `,
  SharedContent: styled.div`
    padding: 10px;
  `
}
