import React, { useContext } from 'react'
import { Modal } from 'components'
import { withSnackbar } from 'notistack'
import { UserContext } from 'providers'
import {
  Grid,
  Input,
  FormGroup,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  FormHelperText,
  Button,
} from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import S from './style'
import { handleHTTPResponse } from 'utils'

class NewNotification extends React.Component {
  state = {
    modalOpen: false,
    loading: false,

    display_url: '',
    post_type: 'image',
    via: 'email',
    score: 500,
    minutes: 10,
    enabled: true,
  }

  toggleModal = () => this.setState({ modalOpen: ! this.state.modalOpen })

  save = async () => {
    this.setState({ loading: true })

    const {
      display_url,
      post_type,
      via,
      score,
      minutes,
      enabled,
    } = this.state

    const data = {
      display_url,
      post_type,
      via,
      score,
      minutes,
      enabled
    }

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + 'sources_subscriptions',
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          },
          method: 'POST',
          body: JSON.stringify(data)
        }
      )
      await handleHTTPResponse(response)
      window.location.reload()
      this.props.enqueueSnackbar('Updated successfully!', { variant: 'success' })
    } catch (e) {
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      this.setState({ loading: false })
    }
  }

  renderForm = () => {
    const { user: { sources } } = this.props
    return (
      <Grid container direction="column" spacing={24}>
        <Grid item>
          <FormGroup>
            <InputLabel htmlFor="display_url">Source</InputLabel>
            <Select
              id="display_url"
              value={this.state.display_url}
              onChange={(e) => this.setState({ display_url: e.target.value })}
            >
              {sources.map(source => {
                return (
                  <MenuItem key={source.id} value={source.display_url}>
                    [{source.site}] {source.display_name || source.source}
                  </MenuItem>
                )
              })}
            </Select>
          </FormGroup>
        </Grid>
        <Grid item>
          <FormGroup>
            <InputLabel htmlFor="post_type">Post type</InputLabel>
            <Select
              id="post_type"
              value={this.state.post_type}
              onChange={(e) => this.setState({ post_type: e.target.value })}
            >
              <MenuItem value="image">Image</MenuItem>
              <MenuItem value="video">Video</MenuItem>
              <MenuItem value="link">Link</MenuItem>
            </Select>
          </FormGroup>
        </Grid>
        <Grid item>
          <FormGroup>
            <InputLabel htmlFor="via">Via</InputLabel>
            <Select
              id="via"
              value={this.state.via}
              onChange={(e) => this.setState({ via: e.target.value })}
            >
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="pushbullet" disabled={!this.props.user.pushbullet_api_token}>
                Pushbullet
              </MenuItem>
            </Select>
          </FormGroup>
        </Grid>
        <Grid item>
          <FormGroup>
            <InputLabel htmlFor="score">Score</InputLabel>
            <Input
              id="score"
              type="number"
              value={this.state.score}
              onChange={(e) => this.setState({ score: e.target.value })}
              />
              <FormHelperText>Sum of likes, comments or shares.</FormHelperText>
          </FormGroup>
        </Grid>
        <Grid item>
          <FormGroup>
            <InputLabel htmlFor="minutes">Minutes</InputLabel>
            <Input
              id="minutes"
              inputProps={{min: 5}}
              type="number"
              value={this.state.minutes}
              onChange={(e) => this.setState({ minutes: e.target.value })}
            />
            <FormHelperText>
              Amount of minutes in which the source should reach the score.
            </FormHelperText>
          </FormGroup>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.enabled}
                onChange={(e) => this.setState({ enabled: e.target.checked })} />
            }
            label="Enabled"
          />
        </Grid>
      </Grid>
    )
  }

  renderModal = () => (
    <Modal open={this.state.modalOpen} toggle={this.toggleModal} size="small">
      <Modal.Header toggle={this.toggleModal}>Add subscription</Modal.Header>
      <Modal.Body>{ this.renderForm() }</Modal.Body>
      <Modal.Footer>
        <Button color="secondary" onClick={this.toggleModal} disabled={this.state.loading}>
          Discard
        </Button>
        <Button color="primary" variant="contained" disabled={this.state.loading}
        onClick={this.save}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )

  render = () => {
    return (
      <>
        <S.Fab color="primary" onClick={this.toggleModal}><AddIcon /></S.Fab>
        { this.renderModal() }
      </>
    )
  }
}

export default withSnackbar((props) => {
  const user = useContext(UserContext)
  return <NewNotification user={user} {...props} />
})
