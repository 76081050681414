import styled from 'styled-components'

export default {
  Center: styled.div`
  align-items: center;
  display: flex;
  height: 96vh;
  width: 96vw;
  justify-content: center;
  `,
}
