import styled from 'styled-components'
import { Typography } from '@material-ui/core'

export default {
  Author: styled(Typography)`
    font-size: 0.9375rem !important;
    flex-basis: 65% !important;
    flex-shrink: 0 !important;
  `,
  Date: styled(Typography)`
    color: rgba(255, 255, 255, 0.7) !important;
    font-size: 0.9375rem !important;
  `
}