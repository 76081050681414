import React, { useContext } from 'react'
import { Backdrop, Switch } from '@material-ui/core'
import { Navbar } from 'components'
import { UserContext } from 'providers'

import Toolbar from './Toolbar'
import Header from './Header'
import Links from './Links'

import S from './style'

class Sidenav extends React.Component {
  state = {
    open: false,
  };

  toggle = () => this.setState({ open: ! this.state.open })
  close  = () => this.setState({ open: false })

  handlePaletteChange = () => {
    const { palette, setPalette } = this.props
    const newPalette = palette === 'dark' ? 'light' : 'dark'
    setPalette(newPalette)
    window.localStorage.setItem('palette', newPalette)
  }

  render = () => {
    const { user, palette } = this.props
    return (
      <>
        <Navbar open={this.state.open} toggle={this.toggle} />
        <S.Sidenav>
          <S.Drawer
            variant="permanent"
            classes={{paper: 'paper'}}
            open={this.state.open}
          >
            {this.state.open && <Backdrop open />}

            <Toolbar open={this.state.open} toggle={this.toggle} />

            <Header open={this.state.open} user={user} />

            <Links open={this.state.open} close={this.close} user={user} />

            {this.state.open && (
              <S.SwitcherWrapper>
                <S.FormControlLabel
                  control={
                    <Switch
                      checked={palette === 'dark'}
                      onChange={this.handlePaletteChange}
                      value={palette}
                      color="primary"
                    />
                  }
                  label="Dark Theme"
                />
              </S.SwitcherWrapper>
            )}
          </S.Drawer>
        </S.Sidenav>
      </>
    );
  }
}

export default (props) => {
  const user = useContext(UserContext)
  return <Sidenav user={user} {...props} />
}
