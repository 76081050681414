import styled from 'styled-components'
import { Dialog } from '@material-ui/core'

export default {
  Dialog: styled(Dialog)`
    & .paper {
      ${props => props.fixedheight && 'min-height: 80vh;'}
      width: ${({width}) => width};
      max-width: none;
      @media (max-width: 720px) {
        width: 100%;
        margin: 20px;
      }
    }
  `
}
