import React, { useContext } from 'react'
import { handleHTTPResponse, getCurrentUser } from 'utils'
import { TextField, Typography, Grid, Button, Link } from '@material-ui/core'
import { UserContext } from 'providers'
import { withSnackbar } from 'notistack'
import { LoginWrapper, SocialLogin } from 'components'

class Login extends React.Component {

  state = {
    email: '',
    password: '',
    loading: false,
  }

  login = async () => {
    const { email, password } = this.state

    const data = {
      grant_type: 'password',
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      username: email,
      password,
      scope: '*'
    }

    let result

    try {
      this.setState({ loading: true })

      const response = await fetch(
        process.env.REACT_APP_API_URL + 'token',
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        }
      )
      if (response.status === 401) {
        return this.props.enqueueSnackbar(
            'Your user and password do not match',
            { variant: 'error' }
          )
      }
      result = await handleHTTPResponse(response)
      localStorage.setItem('accessToken', result.access_token)

      const user = await getCurrentUser()
      if ( user ) {
        window.location.href = '/'
      }
    } catch (e) {
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      this.setState({ loading: false })
    }
  }

  canSubmit = () => this.state.email && this.state.password

  render = () => {
    return (
      <LoginWrapper>
        <form action="/" method="POST" onSubmit={this.login}>
          <Grid container direction="column" justify="space-between" spacing={24}>
            <Grid item>
              <Typography variant="h6" align="center">LOGIN TO YOUR ACCOUNT</Typography>
            </Grid>
            <Grid item>
              <TextField label="Email" type="email" variant="outlined"
                  autoFocus
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  required
                  fullWidth
                  onKeyPress={(e) => e.key === 'Enter' && this.canSubmit() && this.login()}
              />
            </Grid>
            <Grid item>
              <TextField label="Password" type="password" variant="outlined"
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                  required
                  fullWidth
                  onKeyPress={(e) => e.key === 'Enter' && this.canSubmit() && this.login()}
              />
            </Grid>
            <Grid item justify="flex-end">
              <Typography align="right">
              <Button component={Link} onClick={() => this.props.history.push('/forgot_password')}>
                Forgot password?
              </Button>
              </Typography>
            </Grid>
            <SocialLogin />
            <Grid container justify="center">
              <Button
                  variant="contained"
                  color="primary"
                  aria-label="LOG IN"
                  disabled={this.state.loading || ! this.canSubmit()}
                  onClick={this.login}
              >
                LOGIN
              </Button>
            </Grid>
          </Grid>
        </form>
      </LoginWrapper>
    )
  }
}

export default (props) => {
  const Component = withSnackbar(Login)
  const user = useContext(UserContext)
  return <Component user={user} {...props} />
}
