import React from 'react'
import { TextField } from '@material-ui/core'

const renderInputComponent = (inputProps) => {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps
  console.log(classes.input)
  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
        startAdornment: inputProps.startAdornment + ' '
      }}
      {...other}
    />
  );
}

export default renderInputComponent
