import getFooterIcons from './getFooterIcons'
import RenderImages from './RenderImages'
import RenderVideos from './RenderVideos'
import RenderLink from './RenderLink'

export default {
  getFooterIcons,
  RenderImages,
  RenderVideos,
  RenderLink,
}
