import React from 'react'
import PropTypes from 'prop-types'
import { Button, Typography } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import { Modal } from 'components'
import { handleHTTPResponse } from 'utils'
import S from './style'

class Suggestions extends React.Component {
  state = {
    loading: false,
    suggestion: '',
  }

  submit = async () => {
    this.setState({ loading: true });
    const { suggestion } = this.state

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + 'suggestions',
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          },
          method: 'POST',
          body: JSON.stringify({ suggestion })
        }
      )
      await handleHTTPResponse(response)
      this.props.enqueueSnackbar('Thanks for your feedback!!', { variant: 'success' })
      this.props.toggle()
      this.props.closeNavbar && this.props.closeNavbar()
    } catch (e) {
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      this.setState({ loading: false });
    }
  }

  canSubmit = () => this.state.suggestion.length > 3

  render = () => {
    const { open, toggle } = this.props

    return (
      <Modal open={open} toggle={toggle}>
        <Modal.Header toggle={toggle}>Suggestions</Modal.Header>
        <Modal.Body>
          <Typography>You can add your feedback here!</Typography>
          <S.TextField
          multiline rows={5} rowsMax={10}
          value={this.state.suggestion}
          placeholder="..."
          onChange={(e) => this.setState({ suggestion: e.target.value })} />
        </Modal.Body>
        <Modal.Footer>
          <Button color="secondary" onClick={toggle}>
            Discard
          </Button>
          <Button color="primary" variant="contained" disabled={this.state.loading || ! this.canSubmit()}
            onClick={this.submit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

Suggestions.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
}

export default withSnackbar(Suggestions)
