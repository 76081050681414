import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { withSnackbar } from 'notistack'
import { handleHTTPResponse } from 'utils'
import { Modal } from 'components'
import {
  Button,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Select,
  MenuItem,
  Tabs,
  Tab,
  AppBar,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { AccessTime as AccessTimeIcon, SpaceBar as SpaceBarIcon } from '@material-ui/icons'
import { UserContext } from 'providers'
import S from './style'

const TabContainer = (props) => (
  <div style={{marginTop: '10px'}}>
    {props.children}
  </div>
)

class Customize extends React.Component {
  maxPagesToDisplay = 5

  state = {
    loading: false,
    currentTab: 0,

    ...this.props.user.settings,
  }

  togglePageToDisplay(pageId) {
    const pagesToDisplay = this.state.pages_to_display
    const pos = pagesToDisplay.indexOf(pageId)
    if (pos === -1) {
      pagesToDisplay.push(pageId)
    } else {
      pagesToDisplay.splice(pos, 1)
    }

    this.setState({ pages_to_display: pagesToDisplay })
  }

  save = async () => {
    this.setState({ loading: true })
    
    const {
      max_posts_age,
      scheduled_posts_gap,
      schedule_after_local,
      time_format,
      pages_to_display
    } = this.state

    const data = {
      max_posts_age,
      scheduled_posts_gap,
      schedule_after_local,
      time_format,
      pages_to_display
    }

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + 'memes/customize',
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          },
          method: 'POST',
          body: JSON.stringify(data)
        }
      )
      await handleHTTPResponse(response)
      this.props.enqueueSnackbar('Updated successfully!', { variant: 'success' })

      window.ga && window.ga.getAll()[0] && window.ga.getAll()[0].send({
        hitType: 'event',
        eventCategory: 'Save',
        eventAction: 'customize'
      })

      this.props.toggle()
      this.props.closeNavbar && this.props.closeNavbar()
    } catch (e) {
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      this.setState({ loading: false })
    }
  }

  renderForm = () => {
    const { user: { is_extended } } = this.props 
    return (
      <TabContainer>
        <FormControl>
          <InputLabel htmlFor="max-age-of-posts">Max age of posts</InputLabel>
          <Input
            id="max-age-of-posts"
            type="number"
            min={6}
            max={48}
            value={this.state.max_posts_age}
            onChange={(e) => this.setState({ max_posts_age: e.target.value })}
            startAdornment={
              <InputAdornment position="start">
                <AccessTimeIcon />
              </InputAdornment>
            }
            endAdornment={<InputAdornment position="end">hours</InputAdornment>}
          />
      </FormControl>
      { is_extended && (
        <>
          <br /><br /><br /> {/* i am disgusting! */}
          <FormControl>
            <InputLabel htmlFor="scheduled-posts-gap">Gap between scheduled posts</InputLabel>
            <Input
              id="scheduled-posts-gap"
              type="number"
              min={10}
              max={262974} /* six months in minutes */
              value={this.state.scheduled_posts_gap}
              onChange={(e) => this.setState({ scheduled_posts_gap: e.target.value })}
              startAdornment={
                <InputAdornment position="start">
                  <SpaceBarIcon />
                </InputAdornment>
              }
              endAdornment={<InputAdornment position="end">minutes</InputAdornment>}
            />
          </FormControl>
          <br /><br /><br />
          <FormControl>
            <InputLabel htmlFor="schedule_after_local">Apply gap to:</InputLabel>
            <Select
              id="schedule_after_local"
              value={!!this.state.schedule_after_local}
              onChange={(e) => this.setState({ schedule_after_local: e.target.value })}
            >
              <MenuItem value={true}>The Meme Finder only</MenuItem>
              <MenuItem value={false}>Facebook posts and Meme Finder</MenuItem>
            </Select>
          </FormControl>
        </>
      )}
      <br /><br /><br />
      <FormControl>
          <InputLabel htmlFor="time_format">Time format:</InputLabel>
          <Select
            id="time_format"
            value={this.state.time_format}
            onChange={(e) => this.setState({ time_format: e.target.value })}
          >
            <MenuItem value={12}>12 hours (AM/PM)</MenuItem>
            <MenuItem value={24}>24 hours</MenuItem>
          </Select>
      </FormControl>
      </TabContainer>
    )
  }

  renderPagesToDisplay = () => {
    const facebookConnection = this.props.user.connections.find(con => con.site === 'facebook')

    if (! facebookConnection || ! facebookConnection.is_connected) {
      return (
        <TabContainer>
          Either Facebook is not connected or there is a problem with the connection.
        </TabContainer>
      )
    }

    const pages = facebookConnection.extra.pages.map(page => {
      const isChecked = this.state.pages_to_display.includes(page.id)
      const control = (
          <Checkbox
            color="primary"
            checked={isChecked}
            onChange={() => this.togglePageToDisplay(page.id)}
            disabled={! isChecked && this.state.pages_to_display.length >= this.maxPagesToDisplay}
          />
      )
      const label = (
          <>
            <img src={page.picture} height={24} width={24} alt={page.name} />&nbsp;
            {page.name}
          </>
      )
      return (
        <>
          <FormControlLabel key={page.id} control={control} label={label} />
          <br /> {/* they called me a madman */}
        </>
      )
    })

    return <TabContainer>{ pages }</TabContainer>
  }
  

  render = () => {
    const { open, toggle, user: { is_extended } } = this.props

    return (
      <Modal open={open} toggle={toggle}>
        <Modal.Header toggle={toggle}>Customize</Modal.Header>
        <Modal.Body>
          <S.Container>
            <AppBar position="static">
              <Tabs centered value={this.state.currentTab}
              onChange={(e, value) => this.setState({ currentTab: value, idk: e })}>
                <Tab label="Main settings" />
                {is_extended && <Tab label="Pages to display" />}
              </Tabs>
            </AppBar>
            {this.state.currentTab === 0 && this.renderForm()}
            {this.state.currentTab === 1 && is_extended && this.renderPagesToDisplay()}
          </S.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button color="secondary" onClick={toggle} disabled={this.state.loading}>
            Discard
          </Button>
          <Button color="primary" variant="contained" disabled={this.state.loading}
          onClick={this.save}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

Customize.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
}

export default withSnackbar((props) => {
  const user = useContext(UserContext)
  return <Customize user={user} {...props}  />
})
