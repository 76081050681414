const formatDateRelatively = (milliseconds) => {
  const seconds = Math.round((new Date() - new Date(milliseconds)) / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours   = Math.floor(minutes / 60)
  const days    = Math.floor(hours   / 12)
  const weeks   = Math.floor(days    / 7)

  if (seconds < 5) {
    return 'now'
  }
  if (seconds < 60) {
    return String(seconds) + 's'
  }
  if (60 === seconds && seconds < 120) {
    return '1m'
  }
  if (minutes < 60 ) {
    return String(minutes) + 'm'
  }
  if (60 === minutes && minutes < 120) {
    return '1h'
  }
  if (hours < 24) {
    return String(hours) + 'h'
  }
  if (1 === days) {
    return 'yesterday' // all my troubles seem so far away
  }
  if (days < 7) {
    return String(days) + 'd'
  }
  if (7 === days && days < 14) {
    return '1w'
  }
  return String(weeks) + 'w'
}

export default formatDateRelatively
