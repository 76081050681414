import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

export default {
  Grid: styled(Grid)`
    height: 75vh;
  `,
  Paper: styled(Paper)`
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
    border-radius: 4px;
    background-color: #fff;
    border-radius: 4px;
    transform-origin: 50% 50% 0px;
    transform: scaleX(1) scaleY(1);
    padding: 25px;
  `
}