/**
 * Base for all modals
 */
import React from 'react'
import PropTypes from 'prop-types'
import Header from './Header'
import Body from './Body'
import Footer from './Footer'
import S from './style'

class Modal extends React.Component {
  render = () => {
    const { open, toggle, children, size, fixedHeight } = this.props
    const sizes = {
      small: '30%',
      medium: '50%',
      large: '70%'
    }
    const width = sizes[size]
    return (
      <S.Dialog
        open={open}
        onClose={toggle}
        classes={{paper: 'paper'}}
        width={width}
        fixedheight={fixedHeight ? 'true' : ''}>
        {/* should be fixedHeight={true || false} but react raises a warning */}
        {children}
      </S.Dialog>
    )
  }
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  fixedHeight: PropTypes.bool,
}

Modal.defaultProps = {
  size: 'medium',
  fixedHeight: true,
}

Modal.Header = Header
Modal.Body   = Body
Modal.Footer = Footer

export default Modal
