import React, { useContext } from 'react'
import { Typography, Divider } from '@material-ui/core'
import { MemeProvider, SectionContext } from 'providers'
import Memes from './Memes'

const Home = ({ sections, match }) => {
  let section
  if (sections.length > 0) {
    if (match.params && match.params.section) {
      section = match.params.section
    } else {
      section = 'memes'
    }
  }
  section = sections.find(item => item.slug === section)

  let content
  if (sections.length === 0) {
    content = 'No new content.'
  } else if (! section) {
    content = 'Error 404.'
  } else {
    content = (
      <MemeProvider section={section.slug}>
        <Memes />
      </MemeProvider>
    )
  }
  return (
    <>
      <Typography variant="h4">Trending {section.name}</Typography>
      <br />
      <Divider />
      <br />
      {content}
    </>
  )
}

export default (props) => {
  const { sections } = useContext(SectionContext)
  return <Home sections={sections} {...props} />
}
