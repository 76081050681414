import React from 'react'
import { handleHTTPResponse } from 'utils'
import { TextField, Typography, Grid, Button, Link } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import { LoginWrapper } from 'components'

class ForgotPassword extends React.Component {
  state = {
    email: '',
    loading: false,
  }

  resetPassword = async () => {
    this.setState({ loading: true })

    try {
      const data = { email: this.state.email }
      const response = await fetch(process.env.REACT_APP_API_URL + 'password/forgot',
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        }
      )
      
      await handleHTTPResponse(response)
      this.setState({ email: '' })
      this.props.enqueueSnackbar('Email sent successfully!', { variant: 'success' })
    } catch (e) {
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      this.setState({ loading: false })
    }
  }

  canSubmit = () => this.state.email

  render = () => (
    <LoginWrapper>
      <form action="/" method="POST" onSubmit={this.resetPassword}>
        <Grid container direction="column" justify="space-between" spacing={24}>
          <Grid item>
            <Typography variant="h6" align="center">RESET PASSWORD</Typography>
          </Grid>
          <Grid item>
            <TextField label="Email" type="email" variant="outlined"
                autoFocus
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
                required
                fullWidth
                onKeyPress={(e) => e.key === 'Enter' && this.canSubmit() && this.resetPassword()}
            />
          </Grid>
          <Grid item justify="flex-end">
            <Typography align="left">
            <Button component={Link} onClick={() => this.props.history.push('/login')}>
              &larr; Back to log in
            </Button>
            </Typography>
          </Grid>
          <Grid container justify="center">
            <Button
                variant="contained"
                color="primary"
                aria-label="SEND RESET LINK"
                disabled={this.state.loading || ! this.canSubmit()}
                onClick={this.resetPassword}
            >
              SEND RESET LINK
            </Button>
          </Grid>
        </Grid>
      </form>
    </LoginWrapper>
  )
}

export default withSnackbar(ForgotPassword)
