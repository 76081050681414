import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Typography, Button, Grid } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import { handleHTTPResponse, getCurrentUser } from 'utils'
import { UserContext } from 'providers'

class Step3 extends React.Component {
  state = {
    isLoading: false,
  }

  pay = async () => {
    const { token, info: { plan }, goToStep4 } = this.props

    const data = { token, plan }

    try {
      this.setState({ isLoading: true })
      const response = await fetch(
        process.env.REACT_APP_API_URL + 'subscription/create',
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          },
          method: 'POST',
          body: JSON.stringify(data)
        }
      )

      const [, user] = await Promise.all([handleHTTPResponse(response), getCurrentUser()])
      this.props.user.setUser(user)
      
      goToStep4()
    } catch (e) {
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      this.setState({ isLoading: false })
    }
  }

  render = () => {
    const { info, goToStep2 } = this.props
    const { firstName, lastName, plan, card: { brand, last4 } } = info

    const prices = {
      main: 4.99,
      extended: 9.99
    }

    const plans = {
      main: 'Main',
      extended: 'Extended'
    }

    return (
      <>
        <Typography variant="h4">Confirmation</Typography>
        <br />
        <Typography variant="subtitle1">
          <strong>First Name</strong>: {firstName}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Last Name</strong>: {lastName}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Plan</strong>: {plans[plan]}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Method</strong>:&nbsp;
          <i className={"fab fa-cc-" + brand.toLowerCase()} />&nbsp;
          { last4 }
        </Typography>
        
        <br />

        <Grid container spacing={8} justify="center" direction="row">
          <Grid item>
            <Button variant="contained" onClick={goToStep2}>
              Back to step 2
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={this.pay}
              disabled={this.state.isLoading}>
              Pay {prices[plan]}
            </Button>
          </Grid>
        </Grid>
      </>
    )
  }
}

Step3.propTypes = {
  token: PropTypes.string.isRequired,
  info: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    plan: PropTypes.oneOf(['main', 'extended']).isRequired,
    card: PropTypes.shape({
      last4: PropTypes.string.isRequired,
      brand: PropTypes.string.isRequired,
    })
  }).isRequired,
  goToStep2: PropTypes.func.isRequired,
  goToStep4: PropTypes.func.isRequired,
}

export default withSnackbar((props) => {
  const user = useContext(UserContext)
  
  return <Step3 user={user} {...props} />
})
