import React from 'react'
import { handleHTTPResponse } from 'utils'
import { TextField, Typography, Grid, Button, Link } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import { LoginWrapper, SocialLogin } from 'components'

class Register extends React.Component {

  state = {
    email: '',
    name: '',
    password: '',
    loading: false,
  }

  signUp = async () => {
    try {
      this.setState({ loading: true })

      const { email, name, password } = this.state
      const data = { email, name, password }

      const response = await fetch(
        process.env.REACT_APP_API_URL + 'users/create',
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        }
      )

      await handleHTTPResponse(response)

      this.props.enqueueSnackbar(`Welcome to the Meme Finder! We've sent a confirmation link to your email address.`)
    } catch (e) {
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      this.setState({ loading: false })
    }
  }

  canSubmit = () => this.state.name && this.state.email && this.state.password

  render = () => (
    <LoginWrapper>
      <form action="/" method="POST" onSubmit={this.login}>
        <Grid container direction="column" justify="space-between" spacing={24}>
          <Grid item>
            <Typography variant="h6" align="center">WELCOME TO THE MEME FINDER</Typography>
          </Grid>
          <Grid item>
            <TextField label="Name" type="name" variant="outlined"
                autoFocus
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
                required
                fullWidth
                onKeyPress={(e) => e.key === 'Enter' && this.canSubmit() && this.signUp()}
            />
          </Grid>
          <Grid item>
            <TextField label="Email" type="email" variant="outlined"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
                required
                fullWidth
                onKeyPress={(e) => e.key === 'Enter' && this.canSubmit() && this.signUp()}
            />
          </Grid>
          <Grid item>
            <TextField label="Password" type="password" variant="outlined"
                value={this.state.password}
                onChange={(e) => this.setState({ password: e.target.value })}
                required
                fullWidth
                onKeyPress={(e) => e.key === 'Enter' && this.canSubmit() && this.signUp()}
            />
          </Grid>
          <SocialLogin />
          <Grid item justify="flex-end">
            <Typography align="right">
            <Button component={Link} onClick={() => this.props.history.push('/login')}>
              Already have an account?
            </Button>
            </Typography>
          </Grid>
          <Grid container justify="center">
            <Button
                variant="contained"
                color="primary"
                aria-label="SIGN UP"
                disabled={this.state.loading || ! this.canSubmit()}
                onClick={this.signUp}
            >
              GET 7 DAYS OF PREMIUM
            </Button>
          </Grid>
        </Grid>
      </form>
    </LoginWrapper>
  )
}

export default withSnackbar(Register)
