import React from 'react'
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@material-ui/icons'
import {
  Grid,
  MenuItem,
  Select,
  Input,
  InputLabel,
  FormControl,
  IconButton
} from '@material-ui/core'
import Autocomplete from './Autocomplete'

class EditSource extends React.Component {
  state = {
    site: this.props.site || '',
    source: this.props.source || '',
  }

  submit = () => {
    const { index } = this.props
    const sources = [...this.props.stateContainer.state.sources]
    sources[index].source = this.state.source
    sources[index].site = this.state.site

    this.props.stateContainer.setState({ sources, currentSource: null })
  }


  cancel = () => {
    const confirmationMessage = 'Are you sure you wanna cancel?'
    if (! window.confirm(confirmationMessage)) {
      return
    }
    this.setState({
      site: this.props.site,
      source: this.props.source,
    })
    
    const sources = [...this.props.stateContainer.state.sources]
    if (this.state.source.trim() === '') {
      sources.splice(this.props.index, 1)
    }
    this.props.stateContainer.setState({ currentSource: null, sources })
  }

  getSourceInfo = (sourceLink) => {
    let result

    result = sourceLink
             .match(/((twitter|instagram|facebook).com)\/([A-Za-z0-9_]+)$/)

    if (result) {
      return {
        source: result[3],
        site: result[2],
        type: 'profile'
      }
    }

    result = sourceLink.match(/(reddit\.com)\/r\/([A-Za-z0-9_]+)/);

    if (result) {
      return {
        source: result[2],
        site: 'reddit',
        type: 'profile'
      }
    }

    // fb pages without username fb.com/page-name/{id}
    result = sourceLink
             .match(/(facebook.com)\/([A-Za-z0-9-]+)(-)([0-9]+)(\/)?/)
    if (result) {
      return {
        source: result[4],
        site: 'facebook',
        type: 'profile'
      }
    }

    // there's no way to validate a feed without making an request
    // but common indicators are the words 'feed' and 'rss'
    const a = document.createElement('a')
    a.href = sourceLink
    
    if (a.pathname.includes('feed') || a.pathname.includes('rss')) {
      return {
        source: sourceLink,
        site: 'feed',
        type: 'feed'
      }
    }

    return null
  }

  onPaste = (e) => {
    const paste = (e.clipboardData || window.clipboardData).getData('text')

    const data = this.getSourceInfo(paste)

    console.log(data)
    
    if (! data) {
      return
    }

    const { source, site } = data
    this.setState({ site, source })
    e.preventDefault()
  }


  render = () => {
    const { id } = this.props
    const { site, source } = this.state

    const isSourceValid = source && /^[A-Za-z0-9_]+$/.test(source)

    const sitePrefixes = {
      facebook: 'https://facebook.com/',
      twitter: 'https://twitter.com/',
      reddit: 'https://reddit.com/r/',
      feed: '',
    }

    const sitePrefix = sitePrefixes[site] || null

    return (
      <Grid item key={id}>
        <Grid container direction="row" spacing={8}>
          <Grid item>
            <FormControl>
              <InputLabel>Site</InputLabel>
              <Select value={site} onChange={(e) => {
                return this.setState({ site: e.target.value })
              }}>
                <MenuItem value="facebook">Facebook</MenuItem>
                <MenuItem value="twitter">Twitter</MenuItem>
                <MenuItem value="reddit">Reddit</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl>
              <Autocomplete
                site={this.state.site}
                source={this.state.source}
                stateContainer={this}
                inputProps={{ onPaste: this.onPaste, startAdornment: sitePrefix }}
                startAdornment={sitePrefix}

                />
            </FormControl>
          </Grid>
          <Grid item>
          <IconButton onClick={this.submit} disabled={! isSourceValid}>
              <CheckCircleIcon color="primary" />
            </IconButton>
            <IconButton onClick={this.cancel}>
              <CancelIcon color="danger" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default EditSource