import styled from 'styled-components'
import { Typography, Avatar, Drawer, AppBar, ListSubheader, ListItem, FormControlLabel, ListItemText } from '@material-ui/core';

export default {
  Sidenav: styled.div`
    display: flex;
  `,

  Drawer: styled(Drawer)`

    & .paper::-webkit-scrollbar {
        height: 14px;
    }
    
    & .paper::-webkit-scrollbar {
        width: 4px;
    }
    
    & .paper::-webkit-scrollbar-thumb, body .scroll-styled-h::-webkit-scrollbar-thumb {
        background-color: #323438;
        border-radius: 0;
    }
    
    & .paper::-webkit-scrollbar-track, body .scroll-styled-h::-webkit-scrollbar-track {
        border-left: 0;
    }
    
    &, & .paper {
      border-right: none;
      background: #303030;
      flex-shrink: 0;
      white-space: no wrap;
      width: 240px;
      transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
      ${props => !props.open && `
        overflow-x: hidden;
        width: 73px;

        @media (max-width: 720px) {
          width: 0;
        }
      `}
    }
  `,

  Toolbar: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    flex-direction: row;
    background-color: #3C4252;
  `,

  Logo: styled.img`
    width: 24px;
    height: 24px;
    transition: width 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  `,

  Header: styled(AppBar)`
    background-color: #3C4252 !important;
    padding-top: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  `,

  Name: styled(Typography)`
    white-space: nowrap !important;
    color: #FFF !important;
  `,

  Email: styled(Typography)`
    margin-top: 15px !important;
    color: #CCC !important;
    font-size: 12px !important;
  `,

  Avatar: styled(Avatar)`
    display: block;
    background: #303030;
    width: ${({open}) => open  ? '72px' : '40px'} !important;
    height: ${({open}) => open ? '72px' : '40px'} !important;
    margin: ${({open}) => open ? '10px auto -36px auto' : '0 auto 45px auto'};
    border: ${({open}) => open ? '5px solid #303030' : 'initial'};
  `,

  Links: styled.div`
    padding-top: 40px;
    background: #303030;
  `,

  ListSubheader: styled(ListSubheader)`
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7) !important;
    font-size: 12px !important;
    font-weight: 0 !important;
  `,

  ListItem: styled(ListItem)`
    width: ${props => props.open ? 'calc(100% - 16px)' : '100%'} !important;
    height: 40px !important;
    text-decoration: none !important;
    border-radius: ${props => !props.open ? '0' : '0 20px 20px 0'} !important;
    padding-right: ${props => !props.open ? '0' : '12px'} !important;
    padding-left: ${props => !props.open ? '27px' : '2.4rem'} !important;
    display: flex; !important;
    position: relative !important;
    box-sizing: border-box !important;
    text-align: left !important;
    align-items: center !important;
    padding-top: 11px !important;
    padding-bottom: 11px !important;
    justify-content: flex-start !important;

    & .list-item-icon {
      color: #FFF !important;
      & svg {
        font-size: 20px;
      }
    }

    & .list-item-text span {
      color: #FFF !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      font-family: Muli, Roboto, "Helvetica", Arial, sans-serif !important;
      display: ${props => props.open ? 'initial' : 'none'} !important;
    }
  `,

  FormControlLabel: styled(FormControlLabel)`
    span {
      color: #FFF;
    }
  `,

  SwitcherWrapper: styled.div`
    padding-left: 30px;
  `,

  Description: styled.p`
    padding: 10px;
  `,

  Section: styled(ListItemText)`
    padding-left: 60px !important;
    span {
      color: #FFF !important;
    }
  `,

}