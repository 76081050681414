import styled from 'styled-components'
import { Popover } from '@material-ui/core'

export default {
  Popover: styled(Popover)`
    p {
      margin: 10px !important;
    }
  `,
}
