import React from 'react'
import {
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core'
import { AccessTime as AccessTimeIcon } from '@material-ui/icons'
import { handleHTTPResponse } from 'utils'
import { withSnackbar } from 'notistack'

class Step4 extends React.Component {

  state = {
    ...this.props.user.settings,
  }

  save = async () => {
    this.setState({ loading: true })
    
    const {
      max_posts_age,
      scheduled_posts_gap,
      schedule_after_local,
      time_format,
      pages_to_display
    } = this.state

    const data = {
      max_posts_age,
      scheduled_posts_gap,
      schedule_after_local,
      time_format,
      pages_to_display
    }

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + 'memes/customize',
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          },
          method: 'POST',
          body: JSON.stringify(data)
        }
      )
      await handleHTTPResponse(response)
      this.props.enqueueSnackbar('Updated successfully!', { variant: 'success' })

    } catch (e) {
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      this.setState({ loading: false })
    }
  }

  handlePaletteChange = () => {
    const { palette, setPalette } = this.props
    const newPalette = palette === 'dark' ? 'light' : 'dark'
    setPalette(newPalette)
    window.localStorage.setItem('palette', newPalette)
  }

  render = () => {
    const { palette } = this.props

    return (
      <>
          <FormControlLabel
            control={
              <Switch
                checked={palette === 'dark'}
                onChange={this.handlePaletteChange}
                value={palette}
                color="primary"
              />
            }
            label="Dark Theme"
          />

        <br />
        <br />

        <FormControl>
          <InputLabel htmlFor="max-age-of-posts">How old should your memes be?</InputLabel>
          <Input
            id="max-age-of-posts"
            type="number"
            min={6}
            max={48}
            value={this.state.max_posts_age}
            onChange={(e) => this.setState({ max_posts_age: e.target.value })}
            startAdornment={
              <InputAdornment position="start">
                <AccessTimeIcon />
              </InputAdornment>
            }
            endAdornment={<InputAdornment position="end">hours</InputAdornment>}
          />
        </FormControl>

        <br />
        <br />

        <FormControl>
          <InputLabel htmlFor="time_format">Time format:</InputLabel>
          <Select
            id="time_format"
            value={this.state.time_format}
            onChange={(e) => this.setState({ time_format: e.target.value })}
          >
            <MenuItem value={12}>12 hours (AM/PM)</MenuItem>
            <MenuItem value={24}>24 hours</MenuItem>
          </Select>
        </FormControl>

        <br />
        <br />

        <Button color="primary" variant="contained" disabled={this.state.loading}
          onClick={this.save}>
            Save
        </Button>
      </>
    )
  }
}

export default withSnackbar(Step4)
