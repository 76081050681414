/**
 * Generates the dates for the next post
 */
import { addMonths, addMinutes } from 'date-fns'

const getNextPostDates = (user, pageId) => {
  const maxDate = addMonths(new Date(), 6)
  const minDate = addMinutes(new Date(), 11)

  let defaultDate
  // now, check if we have a stored date for this specific page
  let storedDate = localStorage.getItem(
      pageId + '_lastScheduledPost'
    )

  if (! storedDate) {
    // nothing stored, so
    return {minDate, maxDate, defaultDate: minDate}
  }
  const scheduledPostsGap = parseInt(user.settings.scheduled_posts_gap)
  storedDate = new Date(parseInt(storedDate))
  defaultDate = addMinutes(storedDate, scheduledPostsGap)

  // check whether it's valid to be scheduled

  if (defaultDate <= minDate || defaultDate >= maxDate) {
    defaultDate = minDate
  }

  return {minDate, maxDate, defaultDate}
}

export default getNextPostDates
