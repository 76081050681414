import React from 'react'
//import Logo from './Logo'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import S from './style'

const Toolbar = ({ open, toggle }) => {
  return (
    <S.Toolbar>
      <div className="flex flex-1 pr-8">
        {/*<Logo open={open} />*/}
      </div>
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        onClick={toggle}
      >
        <MenuIcon />
      </IconButton>
    </S.Toolbar>
  )
}

export default Toolbar 
