import React from 'react'
import { CardMedia } from '@material-ui/core'

const RenderVideos = ({ videos, embed }) => {
  // luckily, to date, no one returns multiple videos, so we'll always 
  // pick the first one
  // multiple videos will be a headache for a different day.
  const videoUrl = videos[0]
  return <CardMedia src={videoUrl} component={embed ? 'iframe' : 'video'} />
}

export default RenderVideos
