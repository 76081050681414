/**
 * Verifies whether the user is logged in or not and runs a callback with the
 * response.
 */

async function getCurrentUser() {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken === null) {
    return null
  }

  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'user/basics',
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken,
        },
      }
    );
    if (! response.ok) {
      return null
    }
    const result = await response.json()
    return result
  } catch (e) {
    throw new Error('Connection error')
  }
}

export default getCurrentUser;
