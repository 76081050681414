import React from 'react'
import { Route } from 'react-router-dom'
import Login from './Login'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import Register from './Register'
import Verify from './Verify'

export default [
  <Route key="forgot_password" path="/forgot_password" component={ForgotPassword} />,
  <Route key="reset_password" path="/reset_password/:token" component={ResetPassword} />,
  <Route key="verify" path="/verify/:hash" component={Verify} />,
  <Route key="register" path="/register" component={Register} />,
  <Route key="login" path="/login" component={Login} />,
];
