/**
 * Throws the error.
 *
 */

const handleErrors = async (error) => {
  if (typeof error === 'string') {
    // normal error message 
    throw new Error(error);
  }

  if (typeof error.json === 'function') {
    // Response object thrown during a 422 error
    const response = await error.json();
    if (response.message) {
      throw new Error(response.message);
    }
    // Laravel validation with [field => error] which I heavily dislike
    const keys = Object.keys(response);
    throw new Error(response[keys[0]]);
  }
}

export default handleErrors;
