import React from 'react'
import PropTypes from 'prop-types'
import { Input, InputLabel, FormHelperText, withStyles } from '@material-ui/core'

/**
 * This component is basically a hack to style stripe inputs. :)
 * Hours wasted here: 5
 */

const styles = {
  root: {
    marginTop: '10px',
    width: '100%',
    '& .StripeElement': {
      width: '100%',
      height: '25px',
    }
  }
}

const inputProps = {
  style: {
    base: {
      //color: '#FFF',
      fontSize: '16px',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#c23d4b',
    },
  },
}

const ComponentWrapper = ({ Component, handleChange }) => {
  return <Component {...inputProps} onChange={handleChange} />
}

class StripeInput extends React.Component {
  state = { error: null }

  handleChange = (result) => {
    const error  = result.error && result.error.message
    this.setState({ error })
    this.props.onCompleteChange && this.props.onCompleteChange(result.complete)
  }

  render = () => {
    const { component, label, classes } = this.props
    const props = { Component: component, handleChange: this.handleChange }

    return (
      <div>
        <InputLabel>{label}</InputLabel>
        <Input
          error={!!this.state.error}
          classes={classes}
          inputComponent={ComponentWrapper}
          inputProps={props}
        />
        { this.state.error && <FormHelperText>{this.state.error}</FormHelperText> }
      </div>
    )
  }
}

StripeInput.propTypes = {
  component: PropTypes.node.isRequired,
  label: PropTypes.node.isRequired,
  // 1 arg, whether this input is complete or not
  onCompleteChange: PropTypes.func,
}

export default withStyles(styles, { withTheme: true })(StripeInput)
