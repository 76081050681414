import React from 'react'
import S from './style'

import md5 from 'md5'

const Header = ({ user, open }) => {
  const renderLoggedInHeader = () => {
    const { name, email } = user
    const avatar = `https://www.gravatar.com/avatar/${md5(email)}`
    return (
      <>
        {open && (
          <>
            <S.Name>
              {name}
            </S.Name>
            <S.Email>
              {user.email}
            </S.Email>
          </>        
        )}
        <S.Avatar src={avatar} alt="user profile picture" open={open} />
      </>
    )
  }

  const renderLoggedOutHeader = () => {
    if (! open) {
      return
    }
    return (
      <S.Description>
        Welcome to the Meme Finder! Get 7 days of free trial and customize your memes.
      </S.Description>
    )
  }

  return (
    <S.Header position="static"
    elevation={0}>
    { user ? renderLoggedInHeader() : renderLoggedOutHeader() }
    </S.Header>
  )
}

export default Header
