import React, { useState, useContext } from 'react'
import { Grid, IconButton } from '@material-ui/core'
import {
  Public as PublicIcon,
  MyLocation as MyLocationIcon,
  Attachment as AttachmentIcon,
  Sort as SortIcon,

} from '@material-ui/icons'
import { MemeContext } from 'providers'
import Sites from './Sites'
import Sources from './Sources'
import Type from './Type'
import Sort from './Sort'

const Filters = ({ memes: { memes, filter, loading, error } }) => {
  const [state, setState] = useState({
    modalSitesOpen: false,
    modalSourcesOpen: false,
    typeAnchorEl: null,
    sortAnchorEl: null,
  })

  const toggleSitesModal   = () => setState({...state, modalSitesOpen: ! state.modalSitesOpen })
  const toggleSourcesModal = () => setState({...state, modalSourcesOpen: ! state.modalSourcesOpen })

  const handleOpenType  = (e) => setState({...state, typeAnchorEl: e.target })
  const handleCloseType = ()  => setState({...state, typeAnchorEl: null})

  const handleOpenSort  = (e) => setState({...state, sortAnchorEl: e.target })
  const handleCloseSort = ()  => setState({...state, sortAnchorEl: null})

  return (
    <Grid container direction="row" justify="flex-end">
      <Grid item>
        <IconButton color="default" variant="contained" disabled={loading} onClick={toggleSitesModal}>
          <PublicIcon />
        </IconButton>
        {! loading && ! error && (
          <Sites open={state.modalSitesOpen} toggle={toggleSitesModal}
          memes={{memes, filter}} />
        )}
      </Grid>
      <Grid item>
        <IconButton color="default" variant="contained" disabled={loading} onClick={toggleSourcesModal}>
          <MyLocationIcon />
        </IconButton>
        {! loading && ! error && (
          <Sources open={state.modalSourcesOpen} toggle={toggleSourcesModal}
          memes={{memes, filter}} />
        )}
      </Grid>
      <Grid item>
        <IconButton color="default" variant="contained" onClick={handleOpenType} disabled={loading}><AttachmentIcon /></IconButton>
        <Type filter={filter} anchorEl={state.typeAnchorEl} onClose={handleCloseType} />
      </Grid>
      <Grid item>
        <IconButton color="default" variant="contained"  onClick={handleOpenSort} disabled={loading}><SortIcon /></IconButton>
        <Sort filter={filter} anchorEl={state.sortAnchorEl} onClose={handleCloseSort} />
      </Grid>
    </Grid>
  )
}

export default () => {
  const memes = useContext(MemeContext)
  return <Filters memes={memes} />
}
