import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'components'
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'

class Sites extends React.Component {
  allSites = this.props.memes.memes.reduce((accumulator, meme) => {
      if (! accumulator.includes(meme.site)) {
        accumulator.push(meme.site)
      }
      return accumulator
    },
    []
  )

  state = {
    sites: [...this.allSites],
    filter: '',
  }

  toggleSite = (site) => {
    const { sites } = this.state
    const pos = sites.indexOf(site)
    if (pos === -1) {
      sites.push(site)
    } else {
      sites.splice(pos, 1)
    }
    this.setState({ sites })
  }

  checkAll   = () => this.setState({ sites: [...this.allSites] })
  unCheckAll = () => this.setState({ sites: [] })

  save = () => {
    this.props.memes.filter({ sites: this.state.sites })
    this.props.toggle()
    window.ga && window.ga.getAll()[0] && window.ga.getAll()[0].send({
      hitType: 'event',
      eventCategory: 'Filter',
      eventAction: 'sites',
    })
  }

  renderSite = (site) => {
    const sitesNames = {
      twitter:   (<span><i className="fab fa-twitter" />   Twitter   </span>),
      facebook:  (<span><i className="fab fa-facebook" />  Facebook  </span>),
      reddit:    (<span><i className="fab fa-reddit" />    Reddit    </span>),
      instagram: (<span><i className="fab fa-instagram" /> Instagram </span>),
      feed:      (<span><i className="fas fa-rss" />       Feeds     </span>)
    }

    const control = (
        <Checkbox
          color="primary"
          checked={this.state.sites.includes(site)}
          onChange={() => this.toggleSite(site)}
        />
    )
    const label = sitesNames[site]

    return (
      <div key={site}>
        <FormControlLabel control={control} label={label} />
        <br /> {/* they called me a madman */}
      </div>
    )
  }

  render = () => {
    const { open, toggle } = this.props
    const { allSites } = this
    const { sites } = this.state

    const disableCheckAll = sites.length === allSites.length
    const disableUnCheckAll = sites.length === 0

    return (
      <Modal open={open} toggle={toggle} size="small">
        <Modal.Header toggle={toggle}>Sites</Modal.Header>
        <Modal.Body>{allSites.map(this.renderSite)}</Modal.Body>
        <Modal.Footer>
          <Button color="secondary" disabled={disableCheckAll} onClick={this.checkAll}>
            Check all
          </Button>
          <Button color="secondary" disabled={disableUnCheckAll} onClick={this.unCheckAll}>
            Uncheck all
          </Button>
          <Button color="primary" variant="contained" onClick={this.save}>Save</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

Sites.propType = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  memes: PropTypes.shape({
    memes: PropTypes.object.isRequired,
    filter: PropTypes.func.isRequired,
  })
}


export default Sites
