import React from 'react'

import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'

/**
 * Basically the form where they throw money at us
 * 
 */

class Unsubscribed extends React.Component {

  state = {
    step: 1, // 1 = overview, 2 = form, 4 = confirmation, 4 = thank you

    // stored during step 2, passed down to step 3:
    token: '',
    info: null,

    // stored during step 3, passed down to step 4
    transaction_id: null,
  }

  goToStep2 = () => this.setState({ step: 2 })
  goToStep3 = ({ token, info }) => this.setState({ step: 3, token, info })
  goToStep4 = () => this.setState({ step: 4 })
  
  render = () => {
    return (
      <>
        { this.state.step === 1 && <Step1 goToStep2={this.goToStep2} /> }
        { this.state.step === 2 && <Step2 goToStep3={this.goToStep3} /> }
        { this.state.step === 3 && 
          <Step3
            token={this.state.token}
            info={this.state.info}
            goToStep2={this.goToStep2}
            goToStep4={this.goToStep3} />
          }
        { this.state.step === 4 && <Step4 /> }
      </>
    )
  }
}

export default Unsubscribed
