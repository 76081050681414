import React, { useState, useContext } from 'react'
import { Sidenav, GoogleAnalytics, Onboarding } from 'components'
import { UserContext } from 'providers'
import S from './style'
import { Typography } from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import DateFnsUtils from '@date-io/date-fns'
import { Switch, Route } from 'react-router-dom'

import Home from './Home'
import MyMemes from './MyMemes'
import Notifications from './Notifications'
import AuthRoutes from './Auth'

const Routes = ({ user }) => {
  let defaultColor = localStorage.getItem('palette')
  if (! ['light', 'dark'].includes(defaultColor)) {
    defaultColor = 'light'
  }
  const [palette, setPalette] = useState(defaultColor)

  const theme = createMuiTheme({
    typography: {
      useNextVariants: true,
    },
    palette: {
      type: palette,
    },
  })

  return (
    <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Sidenav setPalette={setPalette} palette={palette} />
        <S.Main>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/top/:section" component={Home} />

            {user && (
              <>
                <Route exact path="/my-memes" component={MyMemes} />
                <Route exact path="/notifications" component={Notifications} />
              </>
            )}

            { ! user && AuthRoutes }

            <Route path="/" render={() => <Typography>Error 404</Typography>} />
          </Switch>
          <GoogleAnalytics />
          { user && <Onboarding palette={palette} setPalette={setPalette} /> }
        </S.Main>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  )
}

export default (props) => {
  const user = useContext(UserContext)
  return <Routes {...props} user={user} />
}
