import React from 'react'
import {
  Button,
  Typography,
  Grid,
  IconButton
} from '@material-ui/core'
import {
  AddCircle as AddCircleIcon,
} from '@material-ui/icons'
import { handleHTTPResponse } from 'utils'
import { withSnackbar } from 'notistack'
import DisplaySource from 'components/modals/Sources/DisplaySource'
import EditSource from 'components/modals/Sources/EditSource'

/**** TODO: refactor, stop duplicating code, you piece of shit! *****/
class Step2 extends React.Component {
  state = {
    sources: this.props.sources,
    currentSource: null,
  }

  save = async () => {
    this.setState({ loading: true })

    try {
      const { sources } = this.state
      const response = await fetch(
        process.env.REACT_APP_API_URL + 'sources',
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          },
          method: 'PUT',
          body: JSON.stringify({ sources })
        }
      )
      const result = await handleHTTPResponse(response)
      this.props.enqueueSnackbar('Sources updated successfully!', { variant: 'success' })
      if (result.new_sources) {
        this.props.enqueueSnackbar('Your new sources will be updated briefly.', { variant: 'success'})
      }

    } catch (e) {
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      this.setState({ loading: false });
    }
  }

  deleteSource = (name, index) => {
    const sources = [...this.state.sources]
    const source  = sources[index]

    const reAddSource = () => {
      const sourcesAgain = [...this.state.sources]
      sourcesAgain.splice(index, 0, source)
      this.setState({ sources: sourcesAgain })
    }

    sources.splice(index, 1)
    this.setState({ sources })
    this.props.enqueueSnackbar(`Removed ${name}`, {
      variant: 'info',
      action: <Button onClick={reAddSource}>Undo</Button>,
    })
  }

  addSource = () => {
    const sources = [...this.state.sources]
    sources.unshift({
      site: 'facebook',
      id: String(new Date().getTime()),
      status: {},
      type: 'profile',
    })
    this.setState({ sources, currentSource: 0 })
  }

  mapSource = (source, i) => {
    if (this.state.currentSource === i) {
      return <EditSource {...source} index={i} stateContainer={this} />
    }
    const editSource = (index) => this.setState({ currentSource: index })
    const deleteSource = this.deleteSource

    return (
      <DisplaySource
        source={source}
        index={i}
        editSource={editSource}
        deleteSource={deleteSource}
      />
    )
  }


  render = () => {

    return (
      <>
        <Typography>Your sources are the accounts, pages or feeds where we get the memes from. We've added a couple of sources for you, but you can add more if you wish:</Typography>
        <br />

        <Grid container justify="flex-end">
            <Grid item>
              <IconButton
                disabled={this.state.currentSource !== null}
                onClick={this.addSource}>
                <AddCircleIcon/>
              </IconButton>
            </Grid>
          </Grid>

          <br />
          <br />

          <Grid container direction="column" spacing={16}>
            { this.state.sources.map(this.mapSource) }
          </Grid>


          <br />
          <br />

          <Button color="primary" variant="contained" disabled={this.state.error || this.state.loading}
          onClick={this.save}>
            Save
          </Button>
      </>
    )
  }
}

export default withSnackbar(Step2)
