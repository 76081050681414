import React from 'react'
import S from './style'
import Grid from '@material-ui/core/Grid'

const LoginWrapper = ({ children }) => (
    <S.Grid container justify="center" alignItems="center">
      <Grid item xs={10} sm={10} md={8} lg={4} alignContent="center">
        <S.Paper>
          {/*}
          <Grid container justify="center">
            <img className="w-128 mb-32" src="http://react-material.fusetheme.com/assets/images/logos/fuse.svg" alt="logo" />
          </Grid>*/}
          { children }
        </S.Paper>
      </Grid>
    </S.Grid>
);

export default LoginWrapper;
