import React from 'react'
import PropTypes from 'prop-types'
import S from './style'

class Footer extends React.Component {
  render = () => <S.DialogActions>{this.props.children}</S.DialogActions>
}

Footer.propTypes = {
  children: PropTypes.node.isRequired
}

export default Footer
