import React, { useContext } from 'react'
import { UserConsumer } from 'providers'

export const PostContext  = React.createContext()
export const PostConsumer = PostContext.Consumer

class PostProvider extends React.Component {
  defaultNewState = {
    caption: '',
    images: [],
    previousCaption: '',
    bulkSchedule: false,
    date: new Date(),
  }

  state = {
    // general controls
    currentTab: 0,
    currentPage: null,
    modalOpen: false,
    canPost: true,
    pagesToDisplay: [],

    // tabs
    newTab: {...this.defaultNewState},

    scheduled: {
      posts: null,
      loading: true,
    },

    published: {
      posts: null,
      loading: true,
    },

    // setters
    newPost: (opts) => this.newPost(opts),
    updateNewPost: (opts) => this.updateNewPost(opts),
    toggleModal: () => this.toggleModal(),
    setCurrentTab: (tab) => this.setCurrentTab(tab),
    setCurrentPage: (page) => this.setCurrentPage(page),
    setCanPost: (value) => this.setCanPost(value),

    setScheduledPosts: (posts, loading = false) => this.setScheduledPosts(posts, loading),
    setPublishedPosts: (posts, loading = false) => this.setPublishedPosts(posts, loading),

    addPosts: (scheduled = [], published = []) => this.addPosts(scheduled, published),
    updatePost: (id, tab, info) => this.updatePost(id, tab, info),
    deletePost: (id, tab) => this.deletePost(id, tab),
  }

  componentDidMount = () => {
    this.handlePagesToDisplay()
  }

  componentWillReceiveProps() {
    this.handlePagesToDisplay()
  }

  newPost = (opts) => this.setState({ newTab: { ...this.defaultNewState, ...opts }, modalOpen: true, currentTab: 0 })
  updateNewPost = (opts) => this.setState({ newTab: {...this.state.newTab, ...opts } })
  toggleModal = () => this.setState({ modalOpen: ! this.state.modalOpen })
  setCurrentTab = (currentTab) => this.setState({ currentTab })
  setCurrentPage = (currentPage) => {
    localStorage.setItem('currentPage', currentPage)
    this.setState({ currentPage })
  }
  setCanPost = (canPost) => this.setState({ canPost })
  setScheduledPosts = (posts, loading) => this.setState({ scheduled: { posts: posts, loading }})
  setPublishedPosts = (posts, loading) => this.setState({ published: { posts: posts, loading }})

  handlePagesToDisplay = () => {
    const { user } = this.props
    // not all pages to display should be loaded, because they can be in the 
    // db but the user could've been removed from it.

    // checkout my dirty hacks
    const facebookConnection = user.connections.find(con => con.site === 'facebook')
    // we load the pages the user admins every 15m:
    const pagesThisUserAdmins = facebookConnection.extra.pages
    if (pagesThisUserAdmins.length === 0) {
      return
    }

    // ID of pages the user claims are theirs and wants to use
    const pagesThatShouldBeDisplayed = user.settings.pages_to_display

    const actualPagesToDisplay = pagesThisUserAdmins.filter(page => {
      return pagesThatShouldBeDisplayed.includes(page.id)
    })

    const pagesToDisplay = actualPagesToDisplay.length > 0 ?
      actualPagesToDisplay
    :
      pagesThisUserAdmins.slice(0, 5)

    // -----------------------
    let currentPage = localStorage.getItem('currentPage')
    if (! currentPage) {
      currentPage = pagesToDisplay[0].id
    } else {
      const exists = pagesToDisplay.findIndex(page => page.id === currentPage) >= 0
      if (! exists) {
        currentPage = pagesToDisplay[0].id
      }
    }
    // -----------------------

    this.setState({ pagesToDisplay, currentPage })
  }

  addPosts = (newScheduled, newPublished) => {
    const scheduled = (this.state.scheduled.posts || []).concat(newScheduled || [])
    const published = (this.state.published.posts || []).concat(newPublished || [])

    scheduled.sort((a, b) => a.published_date - b.published_date)
    published.sort((a, b) => b.published_date - a.published_date)
  
    this.setState(
      {
        scheduled: {
          ...this.state.scheduled,
          posts: scheduled
        },
        published: {
          ...this.state.published,
          posts: published
        }
      }
    )
  }

  updatePost = (id, info) => {
    const currentTab = this.state.currentTab === 1 ? 'scheduled' : 'published'
    const { posts } = this.state[currentTab]
    const postIndex = posts.findIndex(post => post.id === id)
    const post = { ...posts[postIndex], ...info }
    posts[postIndex] = post
    this.setState({ [currentTab]: { posts } })    
  }

  deletePost = (id) => {
    const currentTab = this.state.currentTab === 1 ? 'scheduled' : 'published'
    const posts = this.state[currentTab].posts.filter(post => post.id !== id)
    this.setState({ [currentTab]: { posts } })
  }
  

  render = () => (
    <PostContext.Provider value={this.state}>{this.props.children}</PostContext.Provider>
  )
}

export default (props) => {
  const user = useContext(UserConsumer)
  return <PostProvider user={user} {...props} />
}
