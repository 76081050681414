import React from 'react'
import { Grid, Button, Typography } from '@material-ui/core'
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons'
import format from 'date-fns/format'
import { handleHTTPResponse, getCurrentUser } from 'utils'
import { Modal } from 'components'
import Form from '../Form'

class Subscribed extends React.Component {
  state = {
    modalChangeCardOpen: false,
    updatingCard: false,
  }

  toggleModalChangeCard = () => {
    this.setState({ modalChangeCardOpen: ! this.state.modalChangeCardOpen })
  }

  onChangeCard = async (result) => {
    const { token, error } = result
    if (error) {
      return
    }
    const data = {
      token: token.id,
    }

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + 'subscription',
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          },
          method: 'PUT',
          body: JSON.stringify(data)
        }
      )

      await handleHTTPResponse(response)
      this.props.enqueueSnackbar('Your credit card has changed.', { variant: 'success' })

      const user = await getCurrentUser()
      this.props.user.setUser(user)

      this.toggleModalChangeCard()
    } catch (e) {
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  cancel = async () => {
    const confirmationMessage = `Are you sure you want to cancel your subscription?

    You will continue to be subscribed until the end of your period.

    `

    if (! window.confirm(confirmationMessage)) {
      return
    }

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + 'subscription',
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          },
          method: 'DELETE',
        }
      )

      await handleHTTPResponse(response)
      this.props.enqueueSnackbar('Your subscription has been cancelled.', { variant: 'success' })

      const user = await getCurrentUser()
      this.props.user.setUser(user)

      this.props.toggle()
      this.props.closeNavbar && this.props.closeNavbar()
    } catch (e) {
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  renderModalChangePlan = () => {
    return (
      <Modal open={this.state.modalChangeCardOpen} toggle={this.toggleModalChangeCard} size="small">
        <Modal.Header toggle={this.toggleModalChangeCard}>
          Change Card
        </Modal.Header>
        <Modal.Body>
          <Form handleResult={this.onChangeCard} action="update-card" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="contained" onClick={this.toggleModalChangeCard}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  render = () => {
    const { user: { subscription, card_brand, card_last_four } } = this.props
    const { ends_at, subscription: subscriptionName, on_grace_period } = subscription

    const ends_at_formatted = format(
      new Date(ends_at * 1000),
      "LLLL d",
      { awareOfUnicodeTokens: true }
    )

    return (
      <>
      <Grid container alignItems="center" direction="column" spacing={8}>
        <Grid item>
          <Typography variant="h5">
            <CheckCircleIcon /> You are premium!
          </Typography>
        </Grid>
        <Grid item>
          { on_grace_period ? (
            <Typography>You will be premium until the end of your current period on <strong>{ ends_at_formatted }</strong>.</Typography>
          ) : (
            <Typography>Your subscription will renew at the end of your current period on <strong>{ ends_at_formatted }.</strong></Typography>
          )}
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={24}>
        <Grid item sm={12} md={6} lg={6}>
          <Typography variant="subtitle1">Current Plan</Typography>
          
          <Typography>You have the { subscriptionName } plan.</Typography>

          <br />
          <br />
          <br />

          {! on_grace_period && (
            <Button variant="contained" onClick={this.cancel}>
              Cancel
            </Button>
          )}

        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <Typography variant="subtitle1">Payment method</Typography>
          
          <Typography variant="h5">
            <i className={"fab fa-cc-" + card_brand.toLowerCase()} /> &nbsp;
            **** { card_last_four }
          </Typography>

          <br />
          <Button onClick={this.toggleModalChangeCard}>Change</Button>
        </Grid>
      </Grid>
      {this.renderModalChangePlan()}
      </>
    )
  }
}

export default Subscribed
