import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'components'
import { Button, Checkbox, FormControlLabel, Input } from '@material-ui/core'

class Sources extends React.Component {
  allSources = this.props.memes.memes.reduce((accumulator, meme) => {
      const identifier = `${meme.site}:::::::${meme.source_name}`
      if (! accumulator.includes(identifier)) {
        accumulator.push(identifier)
      }
      return accumulator
    },
    []
  )

  state = {
    sources: [...this.allSources],
    filter: '',
  }

  toggleSource = (site) => {
    const { sources } = this.state
    const pos = sources.indexOf(site)
    if (pos === -1) {
      sources.push(site)
    } else {
      sources.splice(pos, 1)
    }
    this.setState({ sources })
  }

  checkAll   = () => this.setState({ sources: [...this.allSources] })
  unCheckAll = () => this.setState({ sources: [] })

  save = () => {
    const sources = this.state.sources.map(source => source.split(':::::::')[1])
    this.props.memes.filter({ sources })
    this.props.toggle()
    window.ga && window.ga.getAll()[0] && window.ga.getAll()[0].send({
      hitType: 'event',
      eventCategory: 'Filter',
      eventAction: 'sources',
    })
  }

  renderSource = (identifier) => {
    const [site, source] = identifier.split(':::::::')

    const sourceNames = {
      twitter:   (<span><i className="fab fa-twitter" />   {source}   </span>),
      facebook:  (<span><i className="fab fa-facebook" />  {source}  </span>),
      reddit:    (<span><i className="fab fa-reddit" />    {source}    </span>),
      instagram: (<span><i className="fab fa-instagram" /> {source}   </span>),
      feed:      (<span><i className="fas fa-rss" />       {source}     </span>)
    }

    const control = (
        <Checkbox
          color="primary"
          checked={this.state.sources.includes(identifier)}
          onChange={() => this.toggleSource(identifier)}
        />
    )
    const label = sourceNames[site]

    return (
      <div key={identifier}>
        <FormControlLabel control={control} label={label} />
        <br /> {/* they called me a madman */}
      </div>
    )
  }

  render = () => {
    const { open, toggle } = this.props
    const { allSources } = this
    const { sources } = this.state

    const disableCheckAll = sources.length === allSources.length
    const disableUnCheckAll = sources.length === 0

    const filter = (identifier) => {
      const filter = this.state.filter.trim().toLowerCase()
      if (filter === '') {
        return true
      }

      const source = identifier.split(":::::::")[1].toLowerCase()
      return source.includes(filter)
    }

    return (
      <Modal open={open} toggle={toggle} size="small">
        <Modal.Header toggle={toggle}>Sources</Modal.Header>
        <Modal.Body>
          <Input
            placeholder="Filter..."
            value={this.state.filter}
            onChange={(e) => this.setState({ filter: e.target.value })}
          />
          <br /><br />
          {allSources.filter(filter).map(this.renderSource)}
        </Modal.Body>
        <Modal.Footer>
          <Button color="secondary" disabled={disableCheckAll} onClick={this.checkAll}>
            Check all
          </Button>
          <Button color="secondary" disabled={disableUnCheckAll} onClick={this.unCheckAll}>
            Uncheck all
          </Button>
          <Button color="primary" variant="contained" onClick={this.save}>Save</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

Sources.propType = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  memes: PropTypes.shape({
    memes: PropTypes.object.isRequired,
    filter: PropTypes.func.isRequired,
  })
}


export default Sources
