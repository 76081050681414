import React from 'react'
import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import Form from '../../Form'

const Step2 = ({ goToStep3 }) => {
  /**
   * function coming from stripe after success
   */
  const handleResult = (result, info) => {
    const { token, error } = result
    if (error) {
      return
    }
    const data = {
      token: token.id,
      info: {
        ...info,
        card: {
          brand: token.card.brand,
          last4: token.card.last4,
        }
      }
    }

    goToStep3(data)
  }

  return (
    <>
      <Typography variant="h4">Payment details</Typography>
      <br />
      <Form handleResult={handleResult} action="subscribe" />
    </>
  )
}

Step2.propTypes = {
  goToStep3: PropTypes.func.isRequired,
}

export default Step2
