import styled from 'styled-components'
import { Typography, AppBar, Button } from '@material-ui/core'

export default {
  Name: styled(Typography)`
    text-transform: initial !important;
    text-align: center !important;
    font-size: 14px !important;
  `,
  Role: styled(Typography)`
    font-size: 12px !important;
  `,
  AppBar: styled(AppBar)`
    text-align: center;
  `,
  RightButton: styled(Button)`
    @media (max-width: 720px) {
      display: none !important;
    }
  `,
  SiteTitle: styled(Typography)`
    display: block !important;
    margin: 0 auto !important;
    padding-left: 100px !important;
    @media (max-width: 720px) {
      padding-left: 0 !important;
      margin-left: -72px;
    }
  `
}