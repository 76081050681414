import React from 'react'

export const SectionContext = React.createContext()
export const SectionConsumer = SectionContext.Consumer

class SectionProvider extends React.Component {
  state = {
    // holds section slug and and name
    sections: this.props.sections,
  }

  render = () => (
    <SectionContext.Provider value={this.state}>
      {this.props.children}
    </SectionContext.Provider>
  )
}

export default SectionProvider
 