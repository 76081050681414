import React, { useState } from 'react'
import { Menu, MenuItem } from '@material-ui/core'

const Type = ({ filter, anchorEl, onClose }) => {
  const [type, setType] = useState('image')
  const changeType = (type) => {
    filter({ type })
    setType(type)
    onClose()
    window.ga && window.ga.getAll()[0] && window.ga.getAll()[0].send({
      hitType: 'event',
      eventCategory: 'Filter',
      eventAction: 'type',
      eventLabel: type
    })
  }
  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={onClose}
    >
      <MenuItem selected={'text' === type} onClick={() => changeType('text')}>Text</MenuItem>
      <MenuItem selected={'image' === type} onClick={() => changeType('image')}>Images</MenuItem>
      <MenuItem selected={'video' === type} onClick={() => changeType('video')}>Videos</MenuItem>
      <MenuItem selected={'gif' === type} onClick={() => changeType('gif')}>Gifs</MenuItem>
      <MenuItem selected={'link' === type} onClick={() => changeType('link')}>Articles</MenuItem>
    </Menu>
  )
}

export default Type
