import React from 'react'
import {
  Grid,
  Typography,
} from '@material-ui/core'
import TwitterLogin from 'react-twitter-auth'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { getCurrentUser, handleHTTPResponse } from 'utils'

// TODO: REFACTOR

class Step3 extends React.Component {
  onTwitterSuccess = async (response) => {
    try {
      await handleHTTPResponse(response)
      const user = await getCurrentUser()
      this.user.setUser(user)
    } catch (e) {
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  onTwitterError = () => {
    this.props.enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' })
  }

  onFacebookResponse = async (result) => {
    if (! result.accessToken) {
      this.props.enqueueSnackbar(
        'There was a problem logging you in. Please try again',
        { variant: 'error' }
      )
      return
    }
    const { accessToken } = result
    const data = {
      code: accessToken
    }

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + 'user/facebook/connect',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
        body: JSON.stringify(data)
      })

      await handleHTTPResponse(response)
      const user = await getCurrentUser()
      this.props.user.setUser(user)
    } catch (e) {
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  render = () => {
    const requiresConnections = !! this.props.user.sources.find(source => {
      return ['facebook', 'twitter'].includes(source.site)
    })

    const twitterConnection = this.props.user.connections.find(con => con.site === 'twitter')
    const twitterName = twitterConnection && twitterConnection.is_connected ? twitterConnection.external_handle : ''

    const facebookConnection = this.props.user.connections.find(con => con.site === 'facebook')
    const facebookName = facebookConnection && facebookConnection.is_connected ? facebookConnection.external_handle : ''

    return (
      <>
        <Typography>Facebook and Twitter sources require you to log in with them to work properly. We don't collect information about you other than your user ID. You don't need to use your personal account.</Typography>

        <br />

        { ! requiresConnections ? (
          <Typography>Since you don't have any Facebook or Twitter sources, you don't need to do this now.</Typography>
        ) : (
          <Typography>You don't need to do this now, but your sources from these places will not work properly until you do.</Typography>
        )}

        <Grid container direction="column" spacing={16} alignItems="center">
          <Grid item />
          <Grid item>
            <TwitterLogin
              onSuccess={this.onTwitterSuccess}
              onFailure={this.onTwitterError}
              customHeaders={{
                'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              }}
              style={{background: 'none', border: 'none'}}
              showIcon={false}
              requestTokenUrl={process.env.REACT_APP_API_URL + 'user/twitter/request_token'}
              loginUrl={process.env.REACT_APP_API_URL + 'user/twitter/connect'}>
                <img src="/img/sign-in-with-twitter.png" alt="sign in with twitter" />
              </TwitterLogin>
          </Grid>
          {twitterName && (
            <Grid item>
              <Typography>Logged in as <strong>@{twitterName}</strong></Typography>
            </Grid>
          )}
          <Grid item>
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              callback={this.onFacebookResponse}
              scope="public_profile,manage_pages,publish_pages"
              render={renderProps => (
                <img onClick={renderProps.onClick} src="/img/sign-in-with-facebook.png"
                alt="sign in with facebook" />
                )}
              />
          </Grid>
          {facebookName && (
            <Grid item>
              <Typography>Logged in as <strong>{facebookName}</strong></Typography>
            </Grid>
          )}
        </Grid>

      </>
    )
  }
}

export default Step3
