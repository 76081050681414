// to create a new post
import React, { useContext } from 'react'
import {
  Button,
  Grid,
  InputAdornment,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import {
  Edit as EditIcon,
  AddAPhoto as AddAPhotoIcon,
  Code as CodeIcon,
  Close as CloseIcon,
} from '@material-ui/icons'
import { withSnackbar } from 'notistack'
import { format } from 'date-fns'
import PagesToDisplay from './PagesToDisplay'
import { Datepicker, Modal } from 'components'
import { UserContext, PostContext } from 'providers'
import { getNextPostDates, handleHTTPResponse } from 'utils'
import S from './style'

class New extends React.PureComponent {
  state = {
    caption: '',
    images: [],
    previousCaption: '',
    bulkSchedule: false,
    date: new Date(),

    datePickerOpen: false,
    anchorEl: null,
    minDate: null,
    maxDate: null,
    uploading: false,
  }

  componentWillMount = () => {
    const { updateNewPost, currentPage } = this.props.posts
    const { defaultDate, minDate, maxDate } = getNextPostDates(this.props.user, currentPage)
    this.setState({ minDate, maxDate })
    updateNewPost({ date: defaultDate })

    // hacky.
    this.props.onRender(this.post)
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.posts.currentPage !== this.props.posts.currentPage) {
      const { defaultDate, minDate, maxDate } = getNextPostDates(
        this.props.user,
        nextProps.posts.currentPage
      )
      this.setState({ minDate, maxDate })
      this.props.posts.updateNewPost({ date: defaultDate })
    }
  }

  post = async (when) => {
    if (! ['now', 'schedule'].includes(when)) {
      throw new Error('Option must be either schedule or now')
    }

    const {
      newTab: { caption, images, bulkSchedule, date },
      addPosts,
      currentPage,
      setCanPost,
      toggleModal,
    } = this.props.posts

    const timeToPost = when === 'now' ? 0 : Math.floor(date / 1000)

    const data = {
      page: currentPage,
      images: images,
      caption: caption,
      publish_time: timeToPost,
      bulk_schedule: bulkSchedule,
    }

    try {
      setCanPost(false)

      const response = await fetch(process.env.REACT_APP_API_URL + 'posts',
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
          },
          body: JSON.stringify(data),
        }
      )
      const result = await handleHTTPResponse(response)

      if (result.errors) {
        for (const error of result.errors) {
          this.props.enqueueSnackbar(error, { variant: 'error' })
        }
      }
      let allTimes = [];

      if (result.published_posts) {
        allTimes = allTimes.concat(
          result.published_posts.map(post => post.published_time)
        );
      }
      if (result.scheduled_posts) {
        allTimes = allTimes.concat(
          result.scheduled_posts.map(post => post.published_time)
        );
      }
      
      if (allTimes.length > 0) {
        const maxTime = Math.max(...allTimes);
        const lastScheduledPost = maxTime * 1000;
        localStorage.setItem(
          `${currentPage}_lastScheduledPost`,
          lastScheduledPost
        );
      }

      addPosts(result.scheduled_posts, result.published_posts)
      toggleModal()
    } catch (e) {
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      setCanPost(true)
    }
  }

  upload = async (files) => {
    const { newTab: { images }, updateNewPost } = this.props.posts

    const data = new FormData()

    for (const file of files) {
      data.append('files[]', file)
    }
    
    this.setState({ uploading: true })

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + 'posts/upload',
        {
          method: 'POST',
          body: data,
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          },
        }
      )
      const result = await handleHTTPResponse(response)

      const newImages   = result.images
      const localImages = images
      const allImages   = localImages.concat(newImages)

      updateNewPost({ images: allImages })
    } catch (e) {
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      this.setState({ uploading: false })
      document.querySelector('#posts__file').value = ''
    }
  }

  addPreviousCaption = () => {
    let { newTab: { previousCaption }, updateNewPost } = this.props.posts
    const caption = previousCaption
    updateNewPost({ caption })
    this.setState({ anchorEl: null })
  }

  selfTag = () => {
    let { newTab: { caption }, updateNewPost } = this.props.posts
    caption = `${caption} {page}`.trim()
    updateNewPost({ caption })
    this.setState({ anchorEl: null })
  }

  togglePicker = () => this.setState({ datePickerOpen: ! this.state.datePickerOpen })

  removeImage = (i) => {
    const { newTab: { images }, updateNewPost } = this.props.posts
    images.splice(i, 1)
    updateNewPost({ images })
  }
  
  onDateSelected = (date) => {
    this.togglePicker()
    if (date) {
      const { updateNewPost } = this.props.posts
      updateNewPost({ date })
    }
  }

  onPaste = (e) => {
    let items = (e.clipboardData ||
      e.originalEvent.clipboardData
     ).items
    let item = items[items.length - 1] // most recent element in clipboard

    if (item.kind === 'file') { // for images and whatever
      this.upload([item.getAsFile()])
      return
    }
  }

  renderUploadingModal = () => (
    <Modal open={true} size="small" fixedHeight={false}>
      <Modal.Header>Uploading...</Modal.Header>
      <Modal.Body><LinearProgress color="primary" /></Modal.Body>
    </Modal>
  )

  render = () => {
    const { user, posts } = this.props
    const { newTab: { images, caption, bulkSchedule, date }, updateNewPost } = posts

    let fullDate
    if (user.settings.time_format === 12) {
      fullDate = format(
        new Date(date),
        "LLLL d',' 'at' hh':'mm aaa",
        { awareOfUnicodeTokens: true }
      )
    } else {
      fullDate = format(
        new Date(date),
        "LLLL d' at' HH':'mm",
        { awareOfUnicodeTokens: true }
      )
    }

    return (
      <>
        <Grid container justify="flex-end">
          <Grid item>
            <Button onClick={this.togglePicker}>
              <i className="fa fa-clock" />&nbsp;
              {fullDate}
            </Button>
          </Grid>
        </Grid>

        <S.TextField
          id="caption"
          multiline
          label="What's on your mind, baby?"
          rows={2}
          rowsMax={3}
          value={caption}
          onChange={(e) => updateNewPost({ caption: e.target.value })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EditIcon />
              </InputAdornment>
            ),
            inputProps: {
              onPaste: this.onPaste
            }
          }}
        />

        <Grid container justify="space-between" direction="row">
          <Grid item>
            <Grid container direction="row">
              <Grid item>
                <IconButton onClick={() => document.querySelector('#posts__file').click()}>
                  <AddAPhotoIcon />
                </IconButton>
                <input type="file" id="posts__file" accept="image/*"
                style={{display: 'none'}}
                multiple={true}
                onChange={(e) => {
                  this.upload(e.target.files)
                }}
                />
              </Grid>
              <Grid item>
                <IconButton onClick={(e) => this.setState({ anchorEl: e.currentTarget })}>
                  <CodeIcon />
                </IconButton>
                <Menu
                  anchorEl={this.state.anchorEl}
                  open={!!this.state.anchorEl}
                  onClose={() => this.setState({ anchorEl: null })}
                  >
                  <MenuItem onClick={this.addPreviousCaption}>
                    Add previous caption
                  </MenuItem>
                  <MenuItem onClick={this.selfTag}>
                    Self-tag
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <PagesToDisplay posts={posts} />
          </Grid>
        </Grid>

        {images.length > 1 && (
          <>
          <Grid container justify="flex-end">
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={bulkSchedule}
                    onChange={() => updateNewPost({ bulkSchedule: ! bulkSchedule })}
                />
                }
                label={"Bulk schedule"} />
              </Grid>
            </Grid>
            <br />
          </>
        )}

        {images.length > 0 && (
          <Grid container spacing={8} direction="row">
            {images.map((image, i) => (
              <Grid item xs={6} md={6} lg={4} xl={4} key={image}>
                <S.CloseButton onClick={() => this.removeImage(i)}>
                  <CloseIcon fontSize="small" />
                </S.CloseButton>
                <img width="100%" height="auto" src={image} alt={image} />
              </Grid>
            ))}
          </Grid>
        )}

        { this.state.datePickerOpen && (
          <Datepicker
          title="Select" selectText="Select"
          onClose={this.onDateSelected}
          minDate={this.state.minDate}
          maxDate={this.state.maxDate}
          defaultDate={date}
          amPm={user.settings.time_format === 12} />
        )}

        { this.state.uploading && this.renderUploadingModal() }
      </>
    )
  }
}

export default withSnackbar((props) => {
  const user = useContext(UserContext)
  const posts = useContext(PostContext)

  const {
    newTab,
    updateNewPost,
    currentTab,
    currentPage,
    setCurrentPage,
    setCanPost,
    addPosts,
    toggleModal,
    pagesToDisplay
  } = posts

  const postProps = {
    newTab,
    updateNewPost,
    currentTab,
    currentPage,
    setCurrentPage,
    setCanPost,
    addPosts,
    toggleModal,
    pagesToDisplay
  }

  return <New user={user} posts={postProps} {...props}  />
})
