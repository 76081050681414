// for scheduled and published posts
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Grid, CircularProgress } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import PagesToDisplay from './PagesToDisplay'
import { PostContext, UserContext } from 'providers'
import { handleHTTPResponse } from 'utils'
import { Post } from 'components'
import S from './style'

class Posts extends React.PureComponent {

  componentDidMount = () => this.fetch()

  componentDidUpdate = (prevProps) => {
    if (prevProps.posts.currentPage !== this.props.posts.currentPage) {
      this.fetch()
    }
  }

  fetch = async () => {
    const { tab } = this.props
    const { setPublishedPosts, setScheduledPosts, currentPage } = this.props.posts
    const API_URL = process.env.REACT_APP_API_URL
    const url = `${API_URL}/posts/${tab}/${currentPage}`

    const func = tab === 'scheduled' ?  setScheduledPosts : setPublishedPosts
    func(null, true)
    try {
      const response = await fetch(
        url,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
          },
        }
      )
      const result = await handleHTTPResponse(response)

      const key = tab === 'scheduled' ? 'scheduled_posts'  : 'published_posts'
      const posts = result[key]
      func(posts)
      this.storeLastScheduledPost(currentPage, posts[posts.length - 1]);
    } catch (e) {
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  reschedulePost = async (id, date) => {
    const { currentPage, updatePost } = this.props.posts

    const currentTab = this.props.posts.currentTab === 1 ? 'scheduled' : 'published'
    const published_time = Math.floor(date / 1000)
    // make the user think it's already done:
    updatePost(id, { published_time })

    // save the original one in case we fak up:
    const { posts } = this.props.posts[currentTab]
    const originalPost = posts.find(post => post.id === id)

    // pls dont go
    window.onbeforeunload = () => "I'm still rescheduling your post. Are " +
                                  "you sure you want to leave?"
    const data = {
      new_publish_time: published_time,
    }

    try {
      const response = await fetch(
        `${ process.env.REACT_APP_API_URL}posts/${currentTab}/${currentPage}/${id}`,
        {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
        method: 'PUT',
        body: JSON.stringify(data)
      })

      const result = await handleHTTPResponse(response)
      const newId = result.new_id
      updatePost(id, { id: newId })
    } catch (e) {
      // add the previous post back.
      updatePost(id, originalPost)
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      window.onbeforeunload = null
    }
  }

  deletePost = async (id) => {
    const { currentPage, deletePost, addPosts } = this.props.posts

    const currentTab = this.props.posts.currentTab === 1 ? 'scheduled' : 'published'
    // make the user think it's already done:
    deletePost(id)

    // save the original one in case we fak up:
    const { posts } = this.props.posts[currentTab]
    const originalPost = posts.find(post => post.id === id)

    // pls dont go
    window.onbeforeunload = () => "I'm still deleting your post. Are " +
                                  "you sure you want to leave?"

    try {
      const response = await fetch(
        `${ process.env.REACT_APP_API_URL}posts/${currentTab}/${currentPage}/${id}`,
        {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
        method: 'DELETE',
      })

      await handleHTTPResponse(response)
    } catch (e) {
      // add the previous post back.
      const newScheduled = currentTab === 'scheduled' ? [originalPost] : []
      const newPublished = currentTab === 'published' ? [originalPost] : []
      addPosts(newScheduled, newPublished)
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      window.onbeforeunload = null
    }
  }

  /**
   * sets lastScheduledPost for this page if the user wants to 
   * always schedule after the last scheduled post
   * 
   * The param 'page' is because this function is going to run AFTER an AJAX
   * request, meaning that 'this.props.currentPage' can change if the user clicks
   * on another page before the AJAX request comes back, and then we'd store
   * the data for the wrong page.
   * 
   * @param {Number} page I'll store the last scheduled post for this page
   * @param {Object} lastPost The last post, if applicable.
   */
  storeLastScheduledPost = (page, lastPost) => {
    if (this.props.tab !== 'scheduled') {
      return;
    }
    if (typeof lastPost === 'undefined') {
      return; // no scheduled posts.
    }
    if (this.props.user.settings.schedule_after_local === "1") {
      return;
    }

    localStorage.setItem(
      page + '_lastScheduledPost',
      lastPost.published_time * 1000
    );
  }

  renderPosts = () => {
    const { user } = this.props
    const { posts, loading } = this.props.posts[this.props.tab]
    const { reschedulePost, deletePost } = this

    if (loading) {
      return <S.Center><CircularProgress /></S.Center>
    }
    if (! posts || posts.length === 0) {
      return <S.Center>No posts to display.</S.Center>
    }
    return posts.map(post => (
      <Post
        key={post.id}
        content={post}
        user={user}
        reschedulePost={reschedulePost}
        deletePost={deletePost}
        />
    ))
  }

  render = () => {
    const { posts } = this.props
    return (
      <>
        <Grid container direction="row" justify="flex-end">
          <Grid item>
            <PagesToDisplay posts={posts} />
          </Grid>
        </Grid>
        <br />
        { this.renderPosts() }
      </>
    )
  }
}

Posts.propTypes = {
  tab: PropTypes.oneOf(['scheduled', 'published']).isRequired,
  posts: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
}

export default withSnackbar((props) => {
  const posts = useContext(PostContext)
  const user  = useContext(UserContext)

  const {
    currentTab,
    currentPage,
    setCurrentPage,
    setPublishedPosts,
    setScheduledPosts,
    updatePost,
    deletePost,
    addPosts,
    scheduled,
    published,
    pagesToDisplay
  } = posts

  const postProps = {
    currentTab,
    currentPage,
    setCurrentPage,
    setPublishedPosts,
    setScheduledPosts,
    updatePost,
    deletePost,
    addPosts,
    scheduled,
    published,
    pagesToDisplay
  }

  return <Posts posts={postProps} user={user} {...props}  />
})
