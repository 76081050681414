import React from 'react'
import { Card, CardHeader, CardMedia } from '@material-ui/core'

const defaultImage = 'https://cdn4.iconfinder.com/data/icons/web-links/512/41-512.png'

const RenderLink = ({ linkInfo: { image, title, description }}) => {
  return (
    <Card>
      <CardHeader title={title} subheader={description} />
      <CardMedia src={image || defaultImage} component="img" />
    </Card>
  )
}

export default RenderLink
