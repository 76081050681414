import React, { useContext } from 'react'
import { UserContext } from 'providers'
import { Modal } from 'components'
import { Button } from '@material-ui/core'
import { handleHTTPResponse } from 'utils'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'

class Onboarding extends React.Component {
  state = {
    step: this.props.user ? this.props.user.onboarding_step : null,
    open: true,
  }
  
  toggle = () => this.setState({ open: ! this.state.open })

  goToStep = async (step) => {
    this.setState({ step })

    if (step === null || (step > 1 && step > this.props.user.onboarding_step)) {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + 'user/change_onboarding_step',
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
            },
            method: 'POST',
            body: JSON.stringify({ step })
          }
        )
        await handleHTTPResponse(response)
  
      } catch (e) {
        console.log(e)
      }
    }
  }

  render = () => {
    const { step } = this.state

    if (step === null) {
      return ''
    }

    const { palette, setPalette } = this.props

    let buttonRightText, buttonRightAction,
        buttonLeftText, buttonLeftAction,
        modalTitle

    switch (step) {
      // few places where switch is really cool and looks clean
      case 1:
        buttonLeftText = 'Skip tutorial'
        buttonLeftAction = () => this.setState({ open: false })

        buttonRightText = 'Add sources'
        buttonRightAction = () => this.goToStep(2)

        modalTitle = 'Welcome to the Meme Finder!'
        break
      case 2:
        buttonLeftText   = 'Back to initial page'
        buttonLeftAction = () => this.goToStep(1)
        
        buttonRightText = 'Add connections'
        buttonRightAction = () => this.goToStep(3)

        modalTitle = 'Add sources'
        break
      case 3:
        buttonLeftText   = 'Back to connections'
        buttonLeftAction = () => this.goToStep(2)
        
        buttonRightText = 'Customize'
        buttonRightAction = () => this.goToStep(4)

        modalTitle = 'Add connections'
        break
      case 4:
        buttonLeftText   = 'Back to customization'
        buttonLeftAction = () => this.goToStep(3)
        
        buttonRightText = 'Get me memes!'
        buttonRightAction = () => {
          this.goToStep(null)
          this.setState({ open: false })
        }

        modalTitle = 'Customize'
        break
      default:
        return ''
    }

    return (
      <Modal open={this.state.open}>
        <Modal.Header toggle={this.toggle}>{ modalTitle }</Modal.Header>
        <Modal.Body>
          { step === 1 && <Step1 /> }
          { step === 2 && <Step2 sources={this.props.user.sources} /> }
          { step === 3 && <Step3 user={this.props.user} /> }
          { step === 4 && <Step4 user={this.props.user} palette={palette} setPalette={setPalette} /> }
        </Modal.Body>
        <Modal.Footer>
          <Button style={{marginRight: 'auto'}} onClick={buttonLeftAction} variant="contained">
              { buttonLeftText }
            </Button>
          <Button onClick={buttonRightAction} color="primary" variant="contained">
            { buttonRightText }
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (props) => {
  const user = useContext(UserContext)
  return <Onboarding user={user} {...props} />
}
