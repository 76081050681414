import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  Grid,
} from '@material-ui/core'
import {
    CheckCircle as CheckCircleIcon,
    Error as ErrorIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
  } from '@material-ui/icons'
import S from './style'

class DisplaySource extends React.PureComponent {
  state = {}

  render = () => {
    const {
      source: { site, source, status, source_name },
      index,
      editSource,
      deleteSource
    } = this.props

    const sourceIcons = {
      twitter:   <i className="fab fa-twitter" />,
      facebook:  <i className="fab fa-facebook" />,
      reddit:    <i className="fab fa-reddit" />,
      instagram: <i className="fab fa-instagram" />,
      feed:      <i className="fas fa-rss" />,
    }

    let sourceName

    if (source_name) {
      sourceName = source_name
    } else {
      const sourceNames = {
        twitter:   `@${source}`,
        facebook:  `${source}`,
        reddit:    `r/${source}`,
        instagram: `@${source}`,
        feed:      `${source}`
      }
      sourceName = sourceNames[site]
    }

    const sourceIcon = sourceIcons[site]

    const key = `${site}-${source}`

    return (
      <Grid item key={key}>
        <Grid container direction="row" justify="space-between">
          <Grid item>
            <Typography>
              {sourceIcon}&nbsp;{sourceName}
            </Typography>
          </Grid>
          <Grid item>
            <EditIcon color="action" onClick={() => editSource(index)} />
            <DeleteIcon color="action" onClick={() => deleteSource(sourceName, index)} />
            { status && ! status.error && (
              <CheckCircleIcon color="primary" />
            )}
            { status && status.error && (
              <span aria-describedby={`popover-${key}`} aria-owns={`popover-${key}`}>
                <ErrorIcon color="secondary" onClick={(e) => {
                  this.setState({ popoverOpenFor: key, anchorEl: e.currentTarget })
                }} />
              </span>
            )}
              <S.Popover
                id={`popover-${key}`}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={this.state.popoverOpenFor === key}
                anchorEl={this.state.popoverOpenFor === key ? this.state.anchorEl : null}
                onClose={() => this.setState({ popoverOpenFor: null, anchorEl: null })}
              >
                <Typography>{status.error}</Typography>
              </S.Popover>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

DisplaySource.propTypes = {
  editSource: PropTypes.func.isRequired,
  deleteSource: PropTypes.func.isRequired,
  source: PropTypes.shape({
    site: PropTypes.string,
    source: PropTypes.string,
    status: PropTypes.string,
    source_name: PropTypes.string,
  }),
  index: PropTypes.number.isRequired,
}

export default DisplaySource