import React from 'react'
/**
 * Returns the icons that will be displayed in the footer
 * scoreIcon, repliesIcon, sharesIcon
 */
const getFooterIcons = (site) => {
  let scoreIcon, repliesIcon

  switch (site) {
    case 'facebook':
    case 'feed':
      scoreIcon = (<i className="fas fa-thumbs-up" />)
      repliesIcon = (<i className="fas fa-comment" />)
      break;
    case 'twitter':
      scoreIcon   = (<i className="fas fa-heart" />)
      repliesIcon = (<i className="fas fa-reply" />)
      break
    case 'reddit':
      scoreIcon = (<i className="fas fa-long-arrow-alt-up" />)
      repliesIcon = (<i className="fas fa-reply" />)
      break
    case 'instagram':
      scoreIcon = (<i className="fas fa-heart" />)
      repliesIcon = (<i className="fas fa-comment" />)
      break
    default:
      break
  }

  const sharesIcon = <i className="fa fa-share" />

  return { scoreIcon, repliesIcon, sharesIcon }
}

export default getFooterIcons
