import React from 'react'
import PropTypes from 'prop-types'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
  StripeProvider,
  Elements,
} from 'react-stripe-elements'
import { Grid, Button, TextField, MenuItem, Select, FormControl, InputLabel } from '@material-ui/core'
import StripeInputWrapper from './StripeInputWrapper'

/**
 * Form used to PAY or UPDATE subscription
 * Validates everything, generates a token and sends it back.
 */

class Form extends React.Component {
  state = {
    firstName: '',
    lastName: '',
    plan: 'main',

    ccComplete: false,
    expiryDateComplete: false,
    cvcComplete: false,

    isLoading: false,
  }

  handleSubmit = async (evt) => {
    evt.preventDefault()
    const { firstName, lastName, plan } = this.state
    if (this.props.stripe) {
      try {
        this.setState({ isLoading: true })
        const result = await this.props.stripe.createToken({ firstName, lastName })
        this.props.handleResult && this.props.handleResult(result, { firstName, lastName, plan })
      } catch (e) {
        console.log(e)
      } finally {
        this.setState({ isLoading: false })
      }
    } else {
      console.log("Stripe.js hasn't loaded yet.")
    }
  }

  canSubmit = () => {
    return (
      this.state.firstName.trim().length > 1 &&
      this.state.lastName.trim().length > 1 &&
      this.state.ccComplete &&
      this.state.expiryDateComplete &&
      this.state.cvcComplete
    )
  }
  
  render = () => {
    const size = this.props.action === 'subscribe' ? 6 : 9
    return (
      <form onSubmit={this.handleSubmit}>
        <Grid container direction="column" spacing={24}>
          <Grid item sm={12} md={size} lg={size}>
            <TextField
              style={{width: '100%'}}
              label="First Name"
              value={this.state.firstName}
              onChange={(e) => this.setState({ firstName: e.target.value })}
              autoFocus
               />
            <TextField
              style={{width: '100%'}}
              label="Last Name"
              value={this.state.lastName}
              onChange={(e) => this.setState({ lastName: e.target.value })}
               />
          </Grid>
          <Grid item sm={12} md={size} lg={size}>
            <StripeInputWrapper
              component={CardNumberElement}
              label="Card number"
              onCompleteChange={ccComplete => this.setState({ ccComplete })}
            />
          </Grid>
          <Grid item sm={12} md={size} lg={size}>
            <StripeInputWrapper
              component={CardExpiryElement}
              label="Card Expiry Date"
              onCompleteChange={expiryDateComplete => this.setState({ expiryDateComplete })}
            />
          </Grid>
          <Grid item sm={12} md={size} lg={size}>
            <StripeInputWrapper
              component={CardCVCElement}
              label="CVC"
              onCompleteChange={cvcComplete => this.setState({ cvcComplete })}
            />
          </Grid>
          { this.props.action === 'subscribe' && (
            <Grid item sm={12} md={6} lg={6}>
            <FormControl>
              <InputLabel htmlFor="subscription_plan">Plan:</InputLabel>
                <Select
                  id="subscription_plan"
                  value={this.state.plan}
                  onChange={(e) => this.setState({ plan: e.target.value })}
                >
                  <MenuItem value="main">Main ($4.99)</MenuItem>
                  {/*<MenuItem value="extended">Extended ($9.99)</MenuItem>*/}
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid item alignContent="center" sm={6} md={size} lg={size}>
            <Button
              variant="contained"
              type="submit"
              disabled={this.state.isLoading || ! this.canSubmit()}
              >
              {this.props.action === 'subscribe' ? 'Next' : 'Update card'}
            </Button>
          </Grid>
        </Grid>
      </form>
    )
  }
}

const FormWithStripe = injectStripe(Form)

const FormWrapper = (props) => (
  <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}>
    <Elements>
      <FormWithStripe {...props} />
    </Elements>
  </StripeProvider>
)

FormWrapper.propTypes = {
  handleResult: PropTypes.node.isRequired,
  action: PropTypes.oneOf(['subscribe', 'update-card']).isRequired,
}

export default FormWrapper
