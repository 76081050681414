import React, { useState, useContext } from 'react'
import { SectionContext } from 'providers'
import { withRouter } from 'react-router-dom'
import { List, Divider, ListItemIcon, ListItemText, Collapse } from '@material-ui/core'
import {
  Home as HomeIcon,
  Collections as CollectionsIcon,
  Settings as SettingsIcon,
  MyLocation as MyLocationIcon,
  SettingsApplications as SettingsApplicationsIcon,
  NoteAdd as NoteAddIcon,
  NotificationImportant as NotificationImportantIcon,
  Star as StarIcon,
  Person as PersonIcon,
  PersonAdd as PersonAddIcon,
} from '@material-ui/icons'
import S from './style'

import { Sources, Customize, Settings, Suggestions, Subscriptions } from 'components/modals'

const Links = ({ open, close, user, history }) => {
  const [state, setState] = useState({
    sourcesOpen: false,
    customizeOpen: false,
    settingsOpen: false,
    suggestionsOpen: false,
    subscriptionsOpen: false,
  })

  const toggler = {
    settings: () => setState({...state, settingsOpen: ! state.settingsOpen }),
    sources: () => setState({...state, sourcesOpen: ! state.sourcesOpen }),
    customize: () => setState({...state, customizeOpen: ! state.customizeOpen }),
    suggestions: () => setState({...state, suggestionsOpen: ! state.suggestionsOpen }),
    subscriptions: () => setState({...state, subscriptionsOpen: ! state.subscriptionsOpen }),
  }

  const goTo = (to) => {
    history.push('/' + to)
    close()
  }

  const current = history.location.pathname

  const { sections } = useContext(SectionContext)

  return (
    <>
      <S.Links>
        <List>
          <S.ListItem button key="home" open={open} selected={current === '/'} onClick={() => goTo('')}>
            <ListItemIcon className="list-item-icon"><HomeIcon /></ListItemIcon>
            <ListItemText className="list-item-text" primary="Home" />
          </S.ListItem>
          { open && sections.length > 0 && (
            <Collapse in={true} timeout="auto" unmountOnExit>
              <List>
                {sections.map(section => (
                  <S.ListItem button onClick={() => goTo('top/' + section.slug)}>
                    <S.Section primary={section.name} />
                  </S.ListItem>
                ))}
              </List>
            </Collapse>
          )}
          { user ? (
            <>
              <S.ListItem
                button
                key="my-memes"
                open={open}
                selected={current === '/my-memes'}
                onClick={() => goTo('my-memes')}
                >
                <ListItemIcon className="list-item-icon"><CollectionsIcon /></ListItemIcon>
                <ListItemText className="list-item-text" primary="My Memes" />
              </S.ListItem>
              <S.ListItem button key="settings" open={open}  onClick={toggler.settings}>
                <ListItemIcon className="list-item-icon"><SettingsIcon /></ListItemIcon>
                <ListItemText className="list-item-text" primary="Settings" />
              </S.ListItem>
              <S.ListItem button key="sources" open={open} onClick={toggler.sources}>
                <ListItemIcon className="list-item-icon"><MyLocationIcon /></ListItemIcon>
                <ListItemText className="list-item-text" primary="Sources" />
              </S.ListItem>
              <S.ListItem button key="customize" open={open} onClick={toggler.customize}>
                <ListItemIcon className="list-item-icon"><SettingsApplicationsIcon /></ListItemIcon>
                <ListItemText className="list-item-text" primary="Customize" />
              </S.ListItem>
              <S.ListItem button key="customize" open={open} onClick={toggler.suggestions}>
                <ListItemIcon className="list-item-icon"><NoteAddIcon /></ListItemIcon>
                <ListItemText className="list-item-text" primary="Suggestions" />
              </S.ListItem>
              <S.ListItem button key="top" open={open} selected={current === '/notifications'}
              onClick={() => goTo('notifications')}>
                <ListItemIcon className="list-item-icon"><NotificationImportantIcon /></ListItemIcon>
                <ListItemText className="list-item-text" primary="Notifications" />
              </S.ListItem>
              {/**/}
              <S.ListItem button key="premium" open={open} onClick={toggler.subscriptions}>
                <ListItemIcon className="list-item-icon"><StarIcon /></ListItemIcon>
                <ListItemText className="list-item-text" primary="Premium" />
              </S.ListItem>{ /* */}
            </>
          ) : (
            <>
              <S.ListItem button key="login" open={open} onClick={() => goTo('login')}>
                <ListItemIcon className="list-item-icon"><PersonIcon /></ListItemIcon>
                <ListItemText className="list-item-text" primary="Log In" />
              </S.ListItem>
              <S.ListItem button key="login" open={open} onClick={() => goTo('register')}>
                <ListItemIcon className="list-item-icon"><PersonAddIcon /></ListItemIcon>
                <ListItemText className="list-item-text" primary="Join" />
              </S.ListItem>
            </>
          )}
        </List>
        <Divider />
      </S.Links>

      { user && (
        <>
          <Sources open={state.sourcesOpen} toggle={toggler.sources} closeNavbar={close} />
          <Customize open={state.customizeOpen} toggle={toggler.customize} closeNavbar={close} />
          <Settings open={state.settingsOpen} toggle={toggler.settings} closeNavbar={close} />
          <Suggestions open={state.suggestionsOpen} toggle={toggler.suggestions} closeNavbar={close} />
          <Subscriptions open={state.subscriptionsOpen} toggle={toggler.subscriptions} closeNavbar={close} />
        </>
      )}
    </>
  )
}


export default withRouter(Links)
