import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'components'
import { Button, Grid } from '@material-ui/core'
import { DatePicker, TimePicker } from 'material-ui-pickers'

class Datepicker extends React.Component {
  state = {
    valid: true,
    date: this.props.defaultDate,
  }

  onChange = (date) => this.setState({ date })
  onClose = () => this.props.onClose(this.state.date)

  canSelect = () => {
    if (this.props.minDate && this.state.date < this.props.minDate) {
      return false
    }
    if (this.props.minDate && this.state.date > this.props.maxDate) {
      return false
    }
    return true
  }

  render = () => {
    const { title, onClose, minDate, maxDate, selectText, amPm } = this.props
    const { onChange } = this
    return (
      <Modal open={true} toggle={() => onClose(null)} size="small" fixedHeight={false}>
        <Modal.Header toggle={() => onClose(null)}>{title}</Modal.Header>
        <Modal.Body>
          <Grid container spacing={24} direction="column"
          justify="center" alignContent="center" alignItems="center">
            <Grid item>
              <DatePicker
                minDate={minDate}
                maxDate={maxDate}
                value={this.state.date}
                onChange={onChange}
              />
            </Grid>
            <Grid item>
              <TimePicker ampm={amPm} value={this.state.date} onChange={onChange} />
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button color="secondary" onClick={() => onClose(null)}>Discard</Button>
          <Button color="primary" disabled={! this.canSelect()} onClick={this.onClose}>
            {selectText}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

Datepicker.propTypes = {
  title: PropTypes.string,
  selectText: PropTypes.string,
  amPm: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  defaultDate: PropTypes.instanceOf(Date).isRequired,
}

Datepicker.defaultProps = {
  title: 'Pick a date and time',
  selectText: 'Select',
  amPm: true,
}

export default Datepicker
