import React, { useContext } from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import S from './style'
import { UserContext, MemeContext, PostContext } from 'providers'
import { Meme } from 'components'
import Masonry from 'react-masonry-component'

class Memes extends React.PureComponent {
  perLoad = 20

  state = {
    renderFrom: 0,
    isRendering: false,
  }

  componentDidMount = () => window.addEventListener('scroll', this.onScroll)
  componentWillUnmount = () => window.removeEventListener('scroll', this.onScroll)

  onScroll = () => {
    if (! ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 500))) {
      return
    }
    if (this.state.isRendering) {
      return
    }

    this.setState({ isRendering: true })
    this.setState({ renderFrom: this.state.renderFrom + 12} );
    this.setState({ isRendering: false })
  }

  render = () => {
    const { memes, loading, filteredMemes, error } = this.props.memes
    const { sources, is_extended } = this.props.user
    const { newPost } = this.props.posts
    
    if (loading) {
      return <S.Center><CircularProgress /></S.Center>
    }
    if (error) {
      return <S.Center>{ error }</S.Center>
    }
    if (sources.length === 0) {
      return <S.Center>It seems like you don't have any sources yet. Try adding some!</S.Center>
    }
    if (memes.length === 0) {
      return <S.Center>It seems like your sources haven't updated in a while.</S.Center>
    }
    if (filteredMemes.length === 0) {
      return <S.Center>No memes satisfy this filter. Try with different filters.</S.Center>
    }

    const { settings: { time_format }, connections } = this.props.user
    const connection = connections.find(con => con.site === 'facebook')
    const canPost = connection && connection.is_connected

    let onPostClick
    if (is_extended && canPost) {
      onPostClick = ({ images, description }) => {
        newPost({ images, previousCaption: description })
      }
    }

    const { renderFrom } = this.state
    let end = renderFrom + this.perLoad

    if (end > filteredMemes.length) {
      end = filteredMemes.length
    }

    const memesToDisplay = filteredMemes.slice(0, end)

    return (
      <Grid container direction="row" spacing={16} component={Masonry}>
        {memesToDisplay.map(meme => (
          <Grid item xs={12} sm={12} md={4} lg={4} key={meme.id}>
            <Meme content={meme} time_format={time_format} onPostClick={onPostClick} />
          </Grid>
          )
        )}
      </Grid>
    )
  }
}

export default () => {
  const user = useContext(UserContext)
  const memes = useContext(MemeContext)
  const posts = useContext(PostContext)

  const { newPost } = posts
  const postProps = { newPost }
  
  return <Memes user={user} memes={memes} posts={postProps} />
}

