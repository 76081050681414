// renders a scheduled or a published post
import React, { useState, memo } from 'react'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Menu,
  MenuItem,
} from '@material-ui/core'
import {
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons'
import { getNextPostDates } from 'utils'
import { Meme, Datepicker } from 'components'
import { format } from 'date-fns'
import S from './style'

const Post = ({ content, user, reschedulePost, deletePost }) => {
  const [state, setState] = useState({
    expanded: false,
    anchorEl: null,
    rescheduling: false
  })

  const toggle = () => setState({ ...state, expanded: ! state.expanded })
  const setAnchorEl = (anchorEl) => setState({ ...state, anchorEl })

  const prepareReschedule = () => setState({ ...state, rescheduling: true })
  const onReschedule = (date) => {
    setState({ ...state, rescheduling: false, anchorEl: null })
    if (! date) {
      return
    }
    reschedulePost(content.id, date)
  }

  const onDelete = () => {
    setState({ ...state, anchorEl: null })
    const confirmationMessage = `Are you sure you want to delete this post?`
    if (! window.confirm(confirmationMessage)) {
      return
    }
    deletePost(content.id)
  }

  const time          = content.published_time * 1000
  const adminCreator  = content.admin_creator
  const isAShare      = content.is_a_share
  const type          = content.type

  let fullDate
  if (user.settings.time_format === 12) {
    fullDate = format(
      new Date(time),
      "LLLL d',' 'at' hh':'mm aaa",
      { awareOfUnicodeTokens: true }
    )
  } else {
    fullDate = format(
      new Date(time),
      "LLLL d' at' HH':'mm",
      { awareOfUnicodeTokens: true }
    )
  }

  if (content.creation_time) {
    // this is the time the meme was scheduled, not published
    // tell the Meme component that this is the time when it was posted
    // so it renders it
    // ILUSION 100
    content = {...content, published_time: content.creation_time}
  }

  return (
    <ExpansionPanel expanded={state.expanded} onChange={toggle}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <S.Author>{adminCreator} {isAShare && '(share)'}</S.Author>
        <S.Date>{fullDate}</S.Date>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container justify="center">
          <Grid item sm={12} md={8} lg={6}>
            <Meme content={content} includeFooter={false}
            onActionClick={(e) => setAnchorEl(e.currentTarget)} />
            <Menu
              anchorEl={state.anchorEl} 
              open={!!state.anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={() => setAnchorEl(null)}>
              {type === 'image' && new Date(time) > new Date() && (
                <MenuItem onClick={prepareReschedule}>Reschedule</MenuItem>
              )}
              <MenuItem onClick={onDelete}>Delete</MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
      { state.rescheduling && (
        <Datepicker
          title="Reschedule" selectText="Reschedule"
          onClose={onReschedule}
          {...getNextPostDates(user)}
          defaultDate={new Date(time)}
          amPm={user.settings.time_format === 12} />
      )}
    </ExpansionPanel>
  )
}

export default memo(Post)
