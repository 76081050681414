import React from 'react'
import { TextField, Typography, Grid, Button } from '@material-ui/core'
import { handleHTTPResponse } from 'utils'
import { withSnackbar } from 'notistack'
import { LoginWrapper } from 'components'

class ResetPassword extends React.Component {

  state = {
    email: '',
    password: '',
    password_confirmation: '',
    loading: false,
  }

  resetPassword  = async () => {
    this.setState({ loading: true })


    const { email, password, password_confirmation } = this.state
    const data = {
      email,
      password,
      password_confirmation,
      token: this.props.match.params.token
    }

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + 'password/reset',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }
    )
    
      await handleHTTPResponse(response)
      this.props.enqueueSnackbar(
        "Your password has been reset. You're being redirected to the login page...",
        { variant: 'info' }
      )
      window.setTimeout(() => window.location = '/login', 2000)
    } catch (e) {
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      this.setState({ loading: false })
    }
  }

  canSubmit = () => (
    this.state.email &&
    this.state.password &&
    this.state.password_confirmation
  )

  render = () => (
    <LoginWrapper>
      <form action="/" method="POST" onSubmit={this.login}>
        <Grid container direction="column" justify="space-between" spacing={24}>
          <Grid item>
            <Typography variant="h6" align="center">CHANGE PASSWORD</Typography>
          </Grid>
          <Grid item>
            <TextField label="Email" type="email" variant="outlined"
                autoFocus
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
                required
                fullWidth
                onKeyPress={(e) => e.key === 'Enter' && this.canSubmit() && this.resetPassword()}
            />
          </Grid>
          <Grid item>
            <TextField label="Password" type="password" variant="outlined"
                value={this.state.password}
                onChange={(e) => this.setState({ password: e.target.value })}
                required
                fullWidth
                onKeyPress={(e) => e.key === 'Enter' && this.canSubmit() && this.resetPassword()}
            />
          </Grid>
          <Grid item>
            <TextField label="Confirm your Password" type="password" variant="outlined"
                value={this.state.password_confirmation}
                onChange={(e) => this.setState({ password_confirmation: e.target.value })}
                required
                fullWidth
                onKeyPress={(e) => e.key === 'Enter' && this.canSubmit() && this.resetPassword()}
            />
          </Grid>
          <Grid container justify="center">
            <Button
                variant="contained"
                color="primary"
                aria-label="CHANGE PASSWORD"
                disabled={this.state.loading || ! this.canSubmit()}
                onClick={this.resetPassword}
            >
              CHANGE PASSWORD
            </Button>
          </Grid>
        </Grid>
      </form>
    </LoginWrapper>
  )
}

export default withSnackbar(ResetPassword)
