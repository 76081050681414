import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { withSnackbar } from 'notistack'
import { Modal } from 'components'
import { handleHTTPResponse } from 'utils'
import {
  Button,
  Typography,
  Divider,
  Grid,
  IconButton
} from '@material-ui/core'
import {
  AddCircle as AddCircleIcon,
} from '@material-ui/icons'
import { UserContext } from 'providers'
import S from './style'
import DisplaySource from './DisplaySource'
import EditSource from './EditSource'

class Sources extends React.Component {
  state = {
    sources: this.props.user.sources,
    loading: false,

    currentSource: null,
  }

  save = async () => {
    this.setState({ loading: true })

    try {
      const { sources } = this.state
      const response = await fetch(
        process.env.REACT_APP_API_URL + 'sources',
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          },
          method: 'PUT',
          body: JSON.stringify({ sources })
        }
      )
      const result = await handleHTTPResponse(response)
      this.props.enqueueSnackbar('Sources updated successfully!', { variant: 'success' })
      if (result.new_sources) {
        this.props.enqueueSnackbar('Your new sources will be updated briefly.', { variant: 'success'})
      }
      this.props.toggle()
      this.props.closeNavbar && this.props.closeNavbar()
      window.ga && window.ga.getAll()[0] && window.ga.getAll()[0].send({
        hitType: 'event',
        eventCategory: 'Save',
        eventAction: 'settings'
      })
    } catch (e) {
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      this.setState({ loading: false });
    }
  }

  deleteSource = (name, index) => {
    const sources = [...this.state.sources]
    const source  = sources[index]

    const reAddSource = () => {
      const sourcesAgain = [...this.state.sources]
      sourcesAgain.splice(index, 0, source)
      this.setState({ sources: sourcesAgain })
    }

    sources.splice(index, 1)
    this.setState({ sources })
    this.props.enqueueSnackbar(`Removed ${name}`, {
      variant: 'info',
      action: <Button onClick={reAddSource}>Undo</Button>,
    })
  }

  addSource = () => {
    const sources = [...this.state.sources]
    sources.unshift({
      site: 'facebook',
      id: String(new Date().getTime()),
      status: {},
      type: 'profile',
    })
    this.setState({ sources, currentSource: 0 })
  }

  mapSource = (source, i) => {
    if (this.state.currentSource === i) {
      return <EditSource {...source} index={i} stateContainer={this} />
    }
    const editSource = (index) => this.setState({ currentSource: index })
    const deleteSource = this.deleteSource

    return (
      <DisplaySource
        source={source}
        index={i}
        editSource={editSource}
        deleteSource={deleteSource}
      />
    )
  }

  render = () => {
    const { open, toggle } = this.props
    const { sources } = this.state

    return (
      <Modal open={open} toggle={toggle}>
        <Modal.Header toggle={toggle}>Sources</Modal.Header>
        <Modal.Body>
          <Typography>Here you can add your sources, one per line.</Typography>
          <br />
          <Divider />
          <br />
          
          <Grid container justify="flex-end">
            <Grid item>
              <IconButton
                disabled={this.state.currentSource !== null}
                onClick={this.addSource}>
                <AddCircleIcon/>
              </IconButton>
            </Grid>
          </Grid>

          <br />
          <br />

          <Grid container direction="column" spacing={16}>
            { sources.map(this.mapSource) }
          </Grid>
          
          {sources.length === 0 && (
            <S.Center><Typography>You don't have any sources yet.</Typography></S.Center>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button color="secondary" onClick={this.props.toggle} disabled={this.state.loading}>
            Discard
          </Button>
          <Button color="primary" variant="contained" disabled={this.state.error || this.state.loading}
          onClick={this.save}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

Sources.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
}

export default withSnackbar((props) => {
  const user = useContext(UserContext)
  return <Sources user={user} {...props}  />
})

export { DisplaySource, EditSource }
