import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { MenuItem, Paper } from '@material-ui/core'
import Autosuggest from 'react-autosuggest'
import InputComponent from './InputComponent'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { handleHTTPResponse } from 'utils'

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const value = suggestion.display_name || suggestion.source
  const matches = match(value, query);
  const parts = parse(value, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          ),
        )}
      </div>
    </MenuItem>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion.display_name || suggestion.source;
}

class Autocomplete extends React.Component {
  state = {
    suggestions: [],
    value: this.props.source,
  }

  handleSuggestionsFetchRequested = async ({ value }) => {
    this.props.stateContainer.setState({ source: value })

    if (value.length < 3) {
      return
    }

    const site = this.props.site
    const filter = value

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + `sources/filter?filter=${filter}&site=${site}`,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          },
        }
      )
      const suggestions = await handleHTTPResponse(response)
      this.setState({ suggestions })
    } catch (e) {
      console.log(e)
    }
  }

  handleSuggestionSelected = (event, { suggestion }) => {
    this.setState({ value: suggestion.source })
    this.props.stateContainer.setState({ source: suggestion.source })
    void event
  }

  handleSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  }

  handleChange = (event, { newValue }) => {
    this.setState({ value: newValue });
    void event // to supress the warning lmao
  }


  render = () => {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Autosuggest
          renderInputComponent={InputComponent}
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          onSuggestionSelected={this.handleSuggestionSelected}
          inputProps={{
            classes,
            placeholder: 'Username, ID or name',
            value: this.state.value,
            onChange: this.handleChange,
            ...this.props.inputProps,
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          />
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    position: 'relative',
    marginTop: '15px',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
})

export default withStyles(styles)(Autocomplete)
