import styled from 'styled-components'

export default {
  Center: styled.div`
  align-items: center;
  display: flex;
  height: 50vh;
  width: 100%;
  justify-content: center;
  `,
}
