import React from 'react'
import {
  CircularProgress,
  Divider,
  Typography,
  Grid,
} from '@material-ui/core'
import { withSnackbar } from 'notistack'
import S from './style'
import { handleHTTPResponse } from 'utils'
import Notification from './Notification'
import NewSubscription from './New'

class Notifications extends React.Component {
  state = {
    loading: true,
    error: null,
    subscriptions: null,
  }

  componentDidMount = async () => {
    this.setState({ loading: true })

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + 'sources_subscriptions',
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
      });
      const subscriptions = await handleHTTPResponse(response)
      this.setState({ subscriptions })
    } catch (e) {
      this.setState({ error: e.message })
    } finally {
      this.setState({ loading: false })
    }
  }

  onNew = (newSubscription) => {
    const subscriptions = [...this.state.subscriptions]
    subscriptions.push(newSubscription)
    this.setState({ subscriptions })
  }

  renderSubscriptions = () => {
    const { loading, error, subscriptions } = this.state

    if (loading) {
      return <S.Center><CircularProgress /></S.Center>
    }
    if (error) {
      return <S.Center>{ error }</S.Center>
    }
    if (subscriptions.length === 0) {
      return <S.Center>You don't have any subscriptions yet. Click on the + sign to add one</S.Center>
    }
    return (
      <Grid container spacing={24}>
        {subscriptions.map(subscription => {
          return (
            <Grid key={subscription.id} item xs={12} lg={3}>
              <Notification {...subscription} />
            </Grid>
          )
        })}
      </Grid>
    )
  }

  render = () => (
    <>
      <Typography variant="h4">Notifications</Typography>
      <br />
      <Divider />
      <br />
      { this.renderSubscriptions() }
      <NewSubscription onNew={this.onNew} />
    </>
  )
}

export default withSnackbar(Notifications)
