import React from 'react'
import { Typography, Button, Grid } from '@material-ui/core'

const Step4 = () => {
  return (
    <>
      <Typography variant="h4">
        Thank you!
      </Typography>
      
      <br />
      
      <Typography>You are now premium. Refresh to enjoy your new features.</Typography>

      <br />
      <br />
      
      <Grid container justify="center">
        <Grid item>
          <Button variant="contained" onClick={() => window.location.reload()}>
            REFRESH
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default Step4
