import React, { useContext } from 'react'
import { UserContext } from 'providers'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Input,
  FormGroup,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  FormHelperText,
  Button,
} from '@material-ui/core'
import { withSnackbar } from 'notistack'
import { handleHTTPResponse } from 'utils'

class Notification extends React.Component {
  state = {
    loading: false,
    post_type: this.props.post_type,
    via: this.props.via,
    score: this.props.score,
    minutes: this.props.minutes,
    enabled: this.props.enabled,
  }

  save = async () => {
    this.setState({ loading: true })

    const { id } = this.props
    const {
      post_type,
      via,
      score,
      minutes,
      enabled,
    } = this.state

    const data = {
      post_type,
      via,
      score,
      minutes,
      enabled
    }

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + 'sources_subscriptions/' + id,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          },
          method: 'PUT',
          body: JSON.stringify(data)
        }
      )
      await handleHTTPResponse(response)
      this.props.enqueueSnackbar('Updated successfully!', { variant: 'success' })
    } catch (e) {
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      this.setState({ loading: false })
    }
  }

  getSourceName = () => {
    const { source: { site, source } } = this.props
    const sourceNames = {
      twitter:   (<span><i className="fab fa-twitter" />   @{source}   </span>),
      facebook:  (<span><i className="fab fa-facebook" />  {source}  </span>),
      reddit:    (<span><i className="fab fa-reddit" />    r/{source}    </span>),
      instagram: (<span><i className="fab fa-instagram" /> @{source} </span>),
      feed:      (<span><i className="fas fa-rss" />       {source}     </span>)
    }
    
    return sourceNames[site]
  }

  renderForm = () => {
    return (
      <Grid container direction="column" spacing={24}>
        <Grid item>
          <FormGroup>
            <InputLabel htmlFor="post_type">Post type</InputLabel>
            <Select
              id="post_type"
              value={this.state.post_type}
              onChange={(e) => this.setState({ post_type: e.target.value })}
            >
              <MenuItem value="image">Image</MenuItem>
              <MenuItem value="video">Video</MenuItem>
              <MenuItem value="link">Link</MenuItem>
            </Select>
          </FormGroup>
        </Grid>
        <Grid item>
          <FormGroup>
            <InputLabel htmlFor="via">Via</InputLabel>
            <Select
              id="via"
              value={this.state.via}
              onChange={(e) => this.setState({ via: e.target.value })}
            >
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="pushbullet" disabled={!this.props.user.pushbullet_api_token}>
                Pushbullet
              </MenuItem>
            </Select>
          </FormGroup>
        </Grid>
        <Grid item>
          <FormGroup>
            <InputLabel htmlFor="score">Score</InputLabel>
            <Input
              id="score"
              type="number"
              value={this.state.score}
              onChange={(e) => this.setState({ score: e.target.value })}
              />
              <FormHelperText>Sum of likes, comments or shares.</FormHelperText>
          </FormGroup>
        </Grid>
        <Grid item>
          <FormGroup>
            <InputLabel htmlFor="minutes">Minutes</InputLabel>
            <Input
              id="minutes"
              inputProps={{min: 5}}
              type="number"
              value={this.state.minutes}
              onChange={(e) => this.setState({ minutes: e.target.value })}
            />
            <FormHelperText>
              Amount of minutes in which the source should reach the score.
            </FormHelperText>
          </FormGroup>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.enabled}
                onChange={(e) => this.setState({ enabled: e.target.checked })} />
            }
            label="Enabled"
          />
        </Grid>
      </Grid>
    )
  }

  render = () => {
    return (
      <Card>
        <CardHeader
          title={this.getSourceName()}
        />
        <CardContent>
          {this.renderForm()}
        </CardContent>
        <CardActions>
          <Button color="primary" style={{ marginLeft: 'auto'}}
            variant="contained"
            disabled={this.state.loading} onClick={this.save}>
            Save
          </Button>
        </CardActions>
      </Card>
    )
  }
}

export default withSnackbar((props) => {
  const user = useContext(UserContext)
  return <Notification user={user} {...props} />
})
