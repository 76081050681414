import React, { useState } from 'react'
import { Menu, MenuItem } from '@material-ui/core'

const Sort = ({ filter, anchorEl, onClose }) => {
  const [type, setSort] = useState('hot')
  // using sort and order exchangeably cause i have mental problems
  const changeSort = (order) => {
    filter({ order })
    setSort(order)
    onClose()
    window.ga && window.ga.getAll()[0] && window.ga.getAll()[0].send({
      hitType: 'event',
      eventCategory: 'Filter',
      eventAction: 'sort',
      eventLabel: order
    })
  }
  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={onClose}
    >
      <MenuItem selected={'hot' === type} onClick={() => changeSort('hot')}>Hot</MenuItem>
      <MenuItem selected={'score' === type} onClick={() => changeSort('score')}>Score</MenuItem>
      <MenuItem selected={'new' === type} onClick={() => changeSort('new')}>New</MenuItem>
      <MenuItem selected={'shares' === type} onClick={() => changeSort('shares')}>Shares</MenuItem>
      <MenuItem selected={'replies' === type} onClick={() => changeSort('replies')}>Replies</MenuItem>
    </Menu>
  )
}

export default Sort
