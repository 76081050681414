import React from 'react'
import { Typography } from '@material-ui/core'

const Step1 = () => {
  return (
    <>
      <Typography variant="body1">
        The Meme Finder is a tool to find the best humourous content on the Internet. You can select your sources and find the best content in each one of them.
      </Typography>

      <br />

      <Typography variant="subtitle1">
        Let's get you started!
      </Typography>
    </>
  )
}

export default Step1
