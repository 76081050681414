import React from 'react'
import { withSnackbar } from 'notistack'
import { Grid } from '@material-ui/core'
import TwitterLogin from 'react-twitter-auth'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { handleHTTPResponse } from 'utils'

class SocialLogin extends React.Component {

  onFacebookResponse = async (result) => {
    if (! result.accessToken) {
      this.props.enqueueSnackbar(
        'There was a problem logging you in. Please try again',
        { variant: 'error' }
      )
      return
    }
    const { accessToken } = result
    const data = {
      code: accessToken
    }

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + 'user/facebook/signin',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      })

      const result = await handleHTTPResponse(response)
      window.localStorage.setItem('accessToken', result.access_token)
      window.location.href = '/'
    } catch (e) {
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  onTwitterSuccess = async (response) => {
    try {
      const result = await handleHTTPResponse(response)
      window.localStorage.setItem('accessToken', result.access_token)
      window.location.href = '/'
    } catch (e) {
      this.props.enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  onTwitterError = () => {
    this.props.enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' })
  }

  render = () => {
    return (
      <Grid container justify="space-between">
        <Grid item>
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            callback={this.onFacebookResponse}
            scope="public_profile,manage_pages,publish_pages,email"
            render={renderProps => (
              <img onClick={renderProps.onClick} src="/img/sign-in-with-facebook.png"
              alt="sign in with facebook" />
              )}
            />
        </Grid>
        <Grid item>
        <TwitterLogin
            onSuccess={this.onTwitterSuccess}
            onFailure={this.onTwitterError}
            customHeaders={{
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            }}
            style={{background: 'none', border: 'none'}}
            showIcon={false}
            requestTokenUrl={process.env.REACT_APP_API_URL + 'user/twitter/request_token'}
            loginUrl={process.env.REACT_APP_API_URL + 'user/twitter/signin'}>
              <img src="/img/sign-in-with-twitter.png" alt="sign in with twitter" />
            </TwitterLogin>
        </Grid>
      </Grid>
    )
  }
}

export default withSnackbar(SocialLogin)
