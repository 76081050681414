import React, { Component } from 'react'
import { getCurrentUser, handleHTTPResponse } from 'utils'
import Routes from './Routes'
import { CssBaseline, CircularProgress } from '@material-ui/core'
import { UserProvider, SectionProvider } from 'providers'
import S from './style'

class App extends Component {
  state = {
    loading: true,
    user: null,
    sections: null,
    error: false,
  }

  componentDidMount = async () => {
    try {
      const [user, sections] = await Promise.all([getCurrentUser(), this.getSections()])
      if (user) {
        const { is_admin } = user
        const { subscription: { subscribed, subscription: subscriptionName, on_trial } } = user
        user.is_extended = is_admin /*|| on_trial || (subscribed && subscriptionName === 'extended')*/
        user.is_main     = is_admin || on_trial || (user.is_extended || (subscribed && subscriptionName === 'main'))
      }
      this.setState({ loading: false, user, sections })
    } catch (e) {
      this.setState({ error: true, loading: false })
    }
  }

  getSections = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + '/sections/list')
    const result   = await handleHTTPResponse(response)
    return result
  }

  render = () => {
    if (this.state.loading) {
      return <S.Center><CircularProgress color="secondary" /></S.Center>
    }
    if (this.state.error) {
      return <S.Center>Error 500</S.Center>
    }

    return (
      <>
        <CssBaseline />
        <UserProvider user={this.state.user}>
          <SectionProvider sections={this.state.sections}>
            <Routes />
          </SectionProvider>
        </UserProvider>
      </>
    )
  }
}

export default App
