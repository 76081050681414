import React from 'react'
import { handleHTTPResponse } from 'utils'
import { LoginWrapper } from 'components'
import { CircularProgress } from '@material-ui/core'

class Verify extends React.Component {
  state = {
    loading: true,
  }

  componentDidMount = async () => {
    const { hash } = this.props.match.params

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + 'verify',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ hash })
      }
    )
    
      await handleHTTPResponse(response)
      window.localStorage.setItem('just_verified', true)
      window.location = '/login'
    } catch (e) {
      this.setState({ error: e.message })
    } finally {
      this.setState({ loading: false })
    }
  }

  render = () => {
    return (
      <LoginWrapper>
        { this.state.loading ? (
          <CircularProgress />
        ) : this.state.error ?
          this.state.error
          :
            'Redirecting...'
        }
      </LoginWrapper>
    )
  }
}

export default Verify

