import handleErrors from './handleErrors'
/**
 * Handles the HTTP response and verifies if something went wrong.
 * If something goes wrong, it'll "throw" it, and it'll be handled by handleErrors
 */
const handleHTTPResponse = (response) => {
  if (! response.ok && ! window.navigator.onLine) {
    throw new Error('It seems like you dont have an active Internet connection. Please try again.');
  }
  if (response.status === 500) {
    throw new Error('Internal error. Will be fixed soon.');
  }
  if (response.status === 401) {
    throw new Error('Something went wrong. Please try logging in again.');
  }
  if (response.status === 422 || response.status === 403) {
    return handleErrors(response);
  }
  if (! response.ok) {
    throw new Error('Unexpected error. :(');
  }
  return response.json();
}

export default handleHTTPResponse;
