import styled from 'styled-components'
import { Fab } from '@material-ui/core'

export default {
  Center: styled.div`
  align-items: center;
  display: flex;
  height: 50vh;
  width: 100%;
  justify-content: center;
  `,
  SiteIcon: styled.i`
    font-size: 30px;
    ${props => props.site === 'reddit'   && 'color: #fb8c00;'}
    ${props => props.site === 'facebook' && 'color: #1976d2;'}
    ${props => props.site === 'twitter'  && 'color: #90caf9;'}
  `,
  Link: styled.a`
    color: inherit;
    text-decoration: initial;
  `,
  Fab: styled(Fab)`
    position: fixed !important;
    bottom: 20px !important;
    right: 20px !important;
  `,
}
