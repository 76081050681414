import React, { useContext } from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import S from './style'
import { MemeContext } from 'providers'
import { Meme } from 'components'
import Masonry from 'react-masonry-component'

class Memes extends React.PureComponent {
  perLoad = 20

  state = {
    renderFrom: 0,
    isRendering: false,
  }

  componentDidMount = () => window.addEventListener('scroll', this.onScroll)
  componentWillUnmount = () => window.removeEventListener('scroll', this.onScroll)

  onScroll = () => {
    if (! ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 500))) {
      return
    }
    if (this.state.isRendering) {
      return
    }

    this.setState({ isRendering: true })
    this.setState({ renderFrom: this.state.renderFrom + 12} );
    this.setState({ isRendering: false })
  }

  render = () => {
    const { memes, loading } = this.props.memes
    
    if (loading) {
      return <S.Center><CircularProgress /></S.Center>
    }
    if (memes.length === 0) {
      return <S.Center>It seems like we haven't got new updates.</S.Center>
    }

    const { renderFrom } = this.state
    let end = renderFrom + this.perLoad

    if (end > memes.length) {
      end = memes.length
    }

    const memesToDisplay = memes.slice(0, end)

    return (
      <Grid container direction="row" spacing={16} component={Masonry}>
        {memesToDisplay.map(meme => (
          <Grid item xs={12} sm={12} md={4} lg={4} key={meme.id}>
            <Meme content={meme} time_format={12} />
          </Grid>
          )
        )}
      </Grid>
    )
  }
}

export default () => {
  const memes = useContext(MemeContext)
  
  return <Memes memes={memes} />
}

