import styled from 'styled-components'
import { Paper } from '@material-ui/core'

export default {
  Main: styled(Paper)`
    padding: 80px 100px;
    color: #FFF;
    background-color: rgba(0, 0, 0, 0.87);
    min-height: 100vh;
    @media (max-width: 720px) {
      padding: 80px 20px;
    }
  `
}
