import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Grid, List, ListItem, Button } from '@material-ui/core'

const Step1 = ({ goToStep2 }) => {
  return (
    <>
      <Typography>
        Welcome to the Meme Finder's premium subscription!
      </Typography>
      <Typography>
        We currently have one premium plan:
      </Typography>

      <br />

      <Grid container spacing={8} alignContent="center">
        <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
          <Typography align="center" variant="h5">Main ($4.99)</Typography>

          <List>
            <ListItem>
              <Typography>
                Get memes from customized sources, including Facebook pages, subreddits and Twitter accounts.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                Be notified of fast-growing posts, where you decide how fast it should be growing. Can be via email or pushbullet.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                Filter the memes by site or source, or by post type.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                Sort the memes by date, votes (or likes), shares and replies.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                Customize how old you want your posts to be.
              </Typography>
            </ListItem>
          </List>
        </Grid>

        { /* 
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography align="center" variant="h5">Extended ($9.99)</Typography>

          <List>
            <ListItem>
              <Typography>
                Post and Schedule to your Facebook pages right from the Meme Finder.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                Bulk-schedule posts with just one click.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                Add a custom gap between your scheduled posts, so you can post every hour without worrying about setting a time.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                Upload images or post from your customized sources.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                See your scheduled and published posts. Reschedule and delete them.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                All the features from the main plan.
              </Typography>
            </ListItem>
          </List>
        </Grid>
        */ }
      </Grid>

      <br />

      <Grid container justify="center">
        <Grid item>
          <Button variant="contained" onClick={goToStep2}>Go premium!</Button>
        </Grid>
      </Grid>

    </>
  )
}

Step1.propTypes = {
  goToStep2: PropTypes.func.isRequired,
}

export default Step1
