import styled from 'styled-components'
import { Fab, Chip } from '@material-ui/core'
import { TextField, IconButton } from '@material-ui/core'

export default {
  Fab: styled(Fab)`
    position: fixed !important;
    bottom: 20px !important;
    right: 20px !important;
  `,
  TabContainer: styled.div`
    padding: 10px;
  `,
  PageToDisplay: styled(Chip)`
    ${props => ! props.current && (`
      & > span {
        display: none !important;
      }
      & > div {
        margin-right: 0 !important;
      }
    `)}
  `,
  Center: styled.div`
    align-items: center;
    display: flex;
    height: 50vh;
    width: 100%;
    justify-content: center;
  `,
  TextField: styled(TextField)`
    width: 100%;
    min-height: 100%;
    margin-top: -15px !important;
    margin-bottom: 10px !important;
  `,
  CloseButton: styled(IconButton)`
    padding: 5px !important;
    float: right !important;
    margin-bottom: -15px !important;
    background-color: #4286f4 !important;
  `
}
