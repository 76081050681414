import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import Lightbox from 'react-images'

const RenderImages = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(null)

  const gridSize = images.length === 1 ? 12 : 6

  const lightBoxImages = images.map(image => ({ src: image }))

  const openLightbox = (currentImage) => setCurrentImage(currentImage)
  const closeLightbox = () => setCurrentImage(null)

  const goToPrevious = () => setCurrentImage(currentImage - 1)
  const goToNext     = () => setCurrentImage(currentImage + 1)

  return (
    <Grid container spacing={8}>
      {images.map((image, i) => (
        <Grid item xs={gridSize} key={image}>
          <img src={image} alt={image} width="100%" height="auto" onClick={() => openLightbox(i)} />
        </Grid>
      ))}
      <Lightbox
        images={lightBoxImages}
        currentImage={currentImage}
        isOpen={currentImage !== null}
        onClose={closeLightbox}
        onClickPrev={goToPrevious}
        onClickNext={goToNext}
        backdropClosesModal={true}
        />
    </Grid>
  )
}

export default RenderImages
