import React, { useContext } from 'react'
import { Button, Tabs, Tab, AppBar } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import { Add as AddIcon } from '@material-ui/icons'
import { Modal } from 'components'
import { UserContext, PostContext } from 'providers'
import New from './New'
import PostsTab from './Posts'
import S from './style'

class Posts extends React.PureComponent {
  constructor(props) {
    super(props)
    this.newTabRef = React.createRef()
  }
  
  state = {
    connected: true,
  }


  componentWillMount = () => {
    const { setCurrentPage } = this.props.posts

    const currentPage = localStorage.getItem('currentPage') || 0
    const pagesToDisplay = this.props.user.settings.pages_to_display
    let actualCurrentPage
    // if the user stops admining a page or they just change their fb account
    // the currentPage may not appear in 'pagesToDisplay'
    if (currentPage !== 0 && pagesToDisplay.includes(currentPage)) {
      actualCurrentPage = currentPage
    } else {
      // otherwise just pick the 1st one again
      actualCurrentPage = pagesToDisplay[0]
    }

    setCurrentPage(actualCurrentPage)

    // 
    const connection = this.props.user.connections.find(con => con.site === 'facebook')
    if (! connection) {
      this.setState({ connected: false })
      return null
    }
    if (connection && ! connection.is_connected) {
      this.props.enqueueSnackbar(
        'Something went wrong with your Facebook connection. Please try re-connecting.',
        {
          variant: 'warning'
        }
      )
      this.setState({ connected: false })
      return null
    }
  }

  renderModal = () => {
    const { posts } = this.props
    const { currentTab, canPost, modalOpen, toggleModal, setCurrentTab, newTab } = posts
    const { caption, images } = newTab

    const isEmpty = caption.trim().length === 0 && images.length === 0

    const _newTab      = <New onRender={post => this.post = post} />
    const scheduledTab = <PostsTab tab="scheduled" />
    const publishedTab = <PostsTab tab="published" />

    return (
      <Modal open={modalOpen} toggle={toggleModal}>
        <Modal.Header toggle={toggleModal}>Posts</Modal.Header>
        <Modal.Body>
          <AppBar position="static">
            <Tabs centered value={currentTab} onChange={(e, value) => setCurrentTab(value, e)}>
              <Tab label="New" />
              <Tab label="Scheduled" />
              <Tab label="Published" />
            </Tabs>
          </AppBar>
          <S.TabContainer>
            <div style={{display: currentTab === 0 ? 'block' : 'none'}}>
              {_newTab}
            </div>
            <div style={{display: currentTab === 1 ? 'block' : 'none'}}>
              {scheduledTab}
            </div>
            <div style={{display: currentTab === 2 ? 'block' : 'none'}}>
              {publishedTab}
            </div>
          </S.TabContainer>
        </Modal.Body>
        {currentTab === 0 && (
        <Modal.Footer>
          <Button disabled={!canPost || isEmpty}
            color="secondary"
            variant="contained"
            style={{marginRight: 'auto'}}
            onClick={() => this.post('now')}>
              Post now
            </Button>
          <Button
            disabled={!canPost || isEmpty}
            color="primary"
            variant="contained"
            onClick={() => this.post('schedule')}>
              Schedule
            </Button>
        </Modal.Footer>
        )}
      </Modal>
    )
  }

  render = () => {
    const { user } = this.props
    if (! user || ! user.is_extended) {
      return null
    }
    if (! this.state.connected) {
      return null
    }
    const { posts: { newPost } } = this.props

    const onClick = () => newPost({ caption: '', images: [], previousCaption: '', canPost: true })
    return (
      <>
        <S.Fab color="primary" onClick={onClick}><AddIcon /></S.Fab>
        { this.renderModal() }
      </>
    )
  }
}

export default withSnackbar((props) => {
  const user = useContext(UserContext)
  const posts = useContext(PostContext)
  const {
    currentTab,
    canPost,
    modalOpen,
    setCurrentPage,
    setCurrentTab,
    newPost,
    toggleModal,
    newTab: { caption, images }
  } = posts

  const postProps = {
    currentTab,
    canPost,
    modalOpen,
    setCurrentPage,
    setCurrentTab,
    newPost,
    toggleModal,
    newTab: { caption, images },
  }

  return <Posts posts={postProps} user={user} {...props} />
})
