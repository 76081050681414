import React from 'react'

export const UserContext = React.createContext()
export const UserConsumer = UserContext.Consumer

class UserProvider extends React.Component {
  state = this.props.user ? {
    ...this.props.user,
    setUser: (user) => this.setUser(user),
  } : null

  setUser = (user) => this.setState({ ...user })

  render = () => (
    <UserContext.Provider value={this.state}>
      {this.props.children}
    </UserContext.Provider>
  )
}

export default UserProvider
 