/**
 * I do really enjoy the name of this one
 */
import React, { createContext } from 'react'
import { handleHTTPResponse } from 'utils'

export const MemeContext  = createContext()
export const MemeConsumer = MemeContext.Consumer

class MemeProvider extends React.Component {
  defaultFilters = {
    // null = all
    // [] = none
    // [..., ...] = those
    sites: null,
    sources: null,
    type: 'image',
    order: 'hot',
    search: {
      sources: '',
    }
  }

  state = {
    loading: true,
    memes: [],
    filteredMemes: [],
    filters: this.defaultFilters,
    errors: [],
    error: null,
    cached: {},

    fetch: () => this.fetch,
    filter: (filters = this.defaultFilters) => this.filter(filters),
  }

  componentDidMount = () => {
    this.fetch()
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.section === prevProps.section) {
      return
    }

    this.fetch()
  }

  fetch = async () => {
    this.setState({ loading: true })

    try {
      const url = this.props.section ? `sections/${this.props.section}` : 'memes'
      if (this.state.cached[url]) {
        this.setState(this.state.cached[url])
        return
      }
      const response = await fetch(process.env.REACT_APP_API_URL + url,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
      });
      const result = await handleHTTPResponse(response)
      const { errors, memes } = result
      const filteredMemes = this.applyFilters(this.defaultFilters, memes)


      const newState = { errors, memes, filteredMemes, loading: false, error: null }
      const { cached } = this.state
      cached[url] = newState

      this.setState({ ...newState, cached })
    } catch (e) {
      console.log(e)
      this.setState({ loading: false, error: e.message })
    }
  }

  applyFilters = (filters, memes = this.state.memes) => {
    filters = { ...this.state.filters, ...filters }

    const filteredMemes = memes.filter(({site, source, type}) =>
      (filters.sites === null || filters.sites.includes(site)) &&
      (filters.sources === null || filters.sources.includes(source)) &&
      filters.type === type
    );

    switch (filters.order) {
      // removed the n word from here cause it hurts some people
      // although i don't use it to hurt anyone
      case 'new':
        filteredMemes.sort((a, b) => a.published_time - b.published_time)
                     .reverse();
        // one day javascript will be so short that you won't
        // even need to write code
        break;
      case 'score':
        filteredMemes.sort((a, b) => a.score - b.score).reverse();
        break;
      case 'shares':  // some sources don't have shares (e.g reddit, ig)
        filteredMemes.sort((a, b) => (a.shares || 0) - (b.shares || 0))
                     .reverse();
        break;
      case 'responses': // some sources don't return replies count (e.g twitter)
        filteredMemes.sort((a, b) => (a.responses || 0) - (b.responses || 0))
                     .reverse();
        break;
      case 'hot':
        filteredMemes.sort((a, b) => a.hotness - b.hotness).reverse();
        break;
      default:
        // this should NEVER EVER HAPPEN
        break;
    }
    this.setState({ filters })
    return filteredMemes
  }

  filter = (filters) => {
    const filteredMemes = this.applyFilters(filters)
    this.setState({ filteredMemes })
  }

  render = () => (
    <MemeContext.Provider value={this.state}>{this.props.children}</MemeContext.Provider>
  )
}

export default MemeProvider