import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { UserContext } from 'providers'
import {
  Card,
  CardHeader,
  Chip,
  CardContent,
  Avatar,
  CardActions,
  Typography,
  IconButton,
} from '@material-ui/core'
import { Send as SendIcon, MoreVert as MoreVertIcon } from '@material-ui/icons'
import Twemoji from 'react-twemoji'
import Linkify from 'react-linkify'
import { formatDateRelatively, formatNumber } from 'utils'
import { format } from 'date-fns'
import S from './style'
import helpers from './helpers'

class Meme extends React.Component {
  getHeader = () => {
    const { content, onActionClick, time_format } = this.props

    const publishedTime = formatDateRelatively(content.published_time * 1000)
    const sourceLink    = content.source_link
    const sourceName    = content.source_name
    const link          = content.link

    let timeFormatted
    if (time_format === 12) {
      timeFormatted = format(
        new Date(content.published_time * 1000),
        "LLLL d',' 'at' hh':'mm aaa",
        { awareOfUnicodeTokens: true }
      );
    } else {
      timeFormatted = format(
        new Date(content.published_time * 1000),
        "LLLL d' at' HH':'mm",
        { awareOfUnicodeTokens: true }
      );
    }

    let siteIcon
    switch (content.site) {
      case 'facebook':
        siteIcon  = (<S.SiteIcon className="fab fa-facebook" site={content.site} />)
        break;
      case 'twitter':
        siteIcon    = (<S.SiteIcon className="fab fa-twitter" site={content.site} />)
        break;
      case 'reddit':
        siteIcon  = (<S.SiteIcon className="fab fa-reddit" site={content.site} />)
        break;
      case 'instagram':
        siteIcon  = (<S.SiteIcon className="fab fa-instagram blue-text" site={content.site} />)
        break;
      case 'feed':
        siteIcon  = (<img alt={content.site} src={content.icon} width={14} height={14} />)
        break;
      default:
        break
    }

    return (
      <CardHeader
        title={<S.Link href={sourceLink} target="_blank" rel="noopener">{sourceName}</S.Link>}
        subheader={
          <S.Link title={timeFormatted} href={link} target="_blank" rel="noopener">
            {publishedTime}
          </S.Link>
        }
        avatar={siteIcon}
        action={onActionClick && (
            <IconButton onClick={onActionClick}><MoreVertIcon /></IconButton>
          )}
         />
    )
  }

  getMedia = () => {
    const { content } = this.props;
    const { RenderImages, RenderVideos, RenderLink } = helpers
    let result = ''
    switch (content.type) {
      case 'image':
        result = <RenderImages images={content.images} />
        break
      case 'video':
        result = <RenderVideos videos={content.videos} embed={content.embed} />
        break
      case 'gif':
        if (content.embed) { // twitter and some others return gifs as videos
          result = <RenderVideos videos={content.images} embed={content.embed} />
        } else {
          result = <RenderImages images={content.images} />
        }
        break
      case 'text':
        if (content.quoted_tweet) {
          result = (<S.SharedContent><Meme content={content.quoted_tweet}
                      includeFooter={false} user={this.props.user} /></S.SharedContent>)
        }
        break
      case 'link':
        result = <RenderLink linkInfo={content.external_link} />
        break
      default:
        return ''
    }
    return result
  }

  getCaption = () => {
    let { description } = this.props.content
    if (! description) {
      return ''
    }
    description = (<Twemoji><Linkify><Typography>{description}</Typography></Linkify></Twemoji>);
    return (
      <CardContent>
        <S.Description>{description}</S.Description>
      </CardContent>
    )
  }

  getFooter = () => {
    const { content, onPostClick } = this.props
    const { images, description } = content
    const { scoreIcon, repliesIcon, sharesIcon } = helpers.getFooterIcons(content.site)

    let score     = content.score     || 0
    let responses = content.responses || 0
    let shares    = content.shares    || 0

    score     = formatNumber(score)
    responses = formatNumber(responses)
    shares    = formatNumber(shares)

    return (
      <CardActions>
        <Chip
          avatar={<Avatar><S.Center>{scoreIcon}</S.Center></Avatar>}
          label={score}
          color="primary"
        />
        <Chip
          avatar={<Avatar><S.Center>{repliesIcon}</S.Center></Avatar>}
          label={responses}
          color="primary"
        />
        <Chip
          avatar={<Avatar><S.Center>{sharesIcon}</S.Center></Avatar>}
          label={shares}
          color="primary"
        />
        {onPostClick && (
        <S.PostButton onClick={() => onPostClick({ images: [...images], description })}>
          <SendIcon />
        </S.PostButton>
        )}
      </CardActions>
    )
  }

  render = () => {
    return (
      <Card>
        { this.getHeader() }
        { this.getMedia() }
        { this.getCaption() }
        { this.props.includeFooter && this.getFooter() }
      </Card>
    )
  }
}

Meme.propTypes = {
  content: PropTypes.object,
  includeFooter: PropTypes.bool,
  onActionClick: PropTypes.func,
}

Meme.defaultProps = {
  includeFooter: true,
}

export default (props) => {
  const user = useContext(UserContext)
  return <Meme user={user} {...props} />
}
