import React from 'react'
import { Typography, Divider } from '@material-ui/core'
import { MemeProvider, PostProvider } from 'providers'
import Memes from './Memes'
import Filters from './Filters'
import Posts from './Posts'

const Home = () => (
    <>
      <Typography variant="h4">My Memes</Typography>
      <br />
      <Divider />
      <br />
      <MemeProvider>
      <PostProvider>
        <Filters />
        <br />
        <Memes />
        <Posts />
      </PostProvider>
      </MemeProvider>
    </>
  )

export default Home
