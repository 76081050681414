import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import S from './style'

class Header extends React.Component {
  render = () => {
    const { toggle, children } = this.props
    return (
      <S.DialogTitle disableTypography>
        <Typography variant="h6">{children}</Typography>
        {toggle ? (
          <S.IconButton aria-label="Close" onClick={toggle}>
            <CloseIcon />
          </S.IconButton>
        ) : null}
      </S.DialogTitle>
    )
  }
}

Header.propTypes = {
  toggle: PropTypes.func,
  children: PropTypes.node.isRequired,
}

Header.defaultProps = {
  toggle: null,
}

export default Header
