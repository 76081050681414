import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { withSnackbar } from 'notistack'
import { UserContext } from 'providers'
import { Modal } from 'components'
import Subscribed from './Subscribed'
import Unsubscribed from './Unsubscribed'

class Subscriptions extends React.Component {
  render = () => {
    const { open, toggle, user, enqueueSnackbar } = this.props
    const { subscription: { subscribed } } = user

    return (
        <Modal open={open} toggle={toggle}>
          <Modal.Header toggle={toggle}>Subscription</Modal.Header>
          <Modal.Body>
            { subscribed ? (
              <Subscribed user={user} enqueueSnackbar={enqueueSnackbar} toggle={toggle} />
            ) : (
              <Unsubscribed user={user} enqueueSnackbar={enqueueSnackbar} toggle={toggle}/>
            )}
          </Modal.Body>
        </Modal>
    )
  }
}

Subscriptions.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
}

export default withSnackbar((props) => {
  const user = useContext(UserContext)
  return <Subscriptions user={user} {...props} />
})
