import styled from 'styled-components'
import { DialogTitle, IconButton } from '@material-ui/core'

export default {
  DialogTitle: styled(DialogTitle)`
    margin: 0;
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  `,
  IconButton: styled(IconButton)`
    top: 8px !important;
    right: 8px !important;
    color: #9e9e9e !important;
    position: absolute !important;
  `,
}
