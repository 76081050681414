import React, { memo } from 'react'
import { Avatar, Grid } from '@material-ui/core'
import S from './style'

const PagesToDisplay = ({ posts }) => {
  const { currentTab, currentPage, setCurrentPage, pagesToDisplay } = posts

  const size = currentTab === 0 ? 24 : 32

  const _pagesToDisplay = pagesToDisplay.map(page => {
    const label = page.id === currentPage ? page.name : null
    const classnames = {
      label: {
        display: page.id === currentPage ? 'initial' : 'none',
      },
    }
    const avatarProps = {
      src: page.picture,
      imgProps: {
        width: size,
        height: size,
        style: {
          opacity: currentPage === page.id ? 1 : 0.4,
        }
      },
    }
    return (
      <Grid item key={page.id}>
        <S.PageToDisplay
          label={label}
          avatar={<Avatar {...avatarProps} />}
          classnames={classnames}
          onClick={() => setCurrentPage(page.id)}
          current={currentPage === page.id ? "true" : ""}
          /* this is stupid but otherwise react raises a warning */
          >
        </S.PageToDisplay>
      </Grid>
    )
  })

  return (
    <Grid container spacing={8}>
      {_pagesToDisplay}
    </Grid>
  )
}

export default memo(PagesToDisplay)
