import React from 'react'
import PropTypes from 'prop-types'
import S from './style'

class Body extends React.Component {
  render = () => <S.DialogContent>{this.props.children}</S.DialogContent>
}

Body.propTypes = {
  children: PropTypes.node.isRequired
}

export default Body
