import React, { useState, useContext } from 'react'
import { Avatar, Toolbar, MenuItem, Menu, Icon, IconButton } from '@material-ui/core'
import { Menu as MenuIcon } from '@material-ui/icons'
import { UserContext } from 'providers'
import { withRouter } from 'react-router-dom'

import S from './style'
import md5 from 'md5'

const logout = () => {
  localStorage.removeItem('accessToken')
  window.location.href = '/'
}

const Navbar = ({ toggle, user }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  
  let role
  if (user) {
    if (user.is_admin) {
      role = 'ADMIN'
    } else if (user.is_extended) {
      role = 'EXTENDED'
    } else if (user.is_main) {
      role = 'MAIN'
    } else {
      role = 'FREE'
    }
  }
  return (
    <div>
      <S.AppBar position="fixed" color="primary">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={toggle}
          >
            <MenuIcon />
          </IconButton>
          <S.SiteTitle variant="h6" color="inherit">
            Meme Finder
          </S.SiteTitle>
          { user && (
            <>
            <S.RightButton
              className="h-64"
              aria-owns={!!anchorEl ? 'menu-appbar' : undefined}
              aria-haspopup="true"
              onClick={(e) => setAnchorEl(e.currentTarget)}
              color="inherit"
            >
              <Avatar src={`https://www.gravatar.com/avatar/${md5(user.email)}`} />

              <div className="hidden md:flex flex-col ml-12 items-start">
                <S.Name component="span">
                    {user.name}
                </S.Name>
                <S.Role variant="button" color="textSecondary">
                    { role }
                </S.Role>
              </div>

              <Icon className="text-16 ml-12 hidden sm:flex" variant="action">keyboard_arrow_down</Icon>

            </S.RightButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={!!anchorEl}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem onClick={logout}>Log out</MenuItem>
            </Menu>
            </>
          )}
        </Toolbar>
      </S.AppBar>
    </div>
  );
}

export default withRouter((props) => {
  const user = useContext(UserContext)
  return <Navbar user={user} {...props} />
})
